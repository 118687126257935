import React, { useState,useEffect } from 'react';

import axios from 'axios';
import Refresh from '../icons/refresh.png'
import esclama from '../icons/esclama.png'
import Right from '../icons/right.png'

import green from "../icons/checked-green.png"
import red from '../icons/circle-red.png'

import Left from '../icons/remove.png'
import Logo from "../../images/Logo.png"

import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';



const API_URL_DIA = 'https://api-production-d9e6.up.railway.app/calendario';
const API_URL_BUSCA_DIAS_DISP = 'https://api-production-d9e6.up.railway.app/getDiasDisp';//IDFUNC ATUALIZACAO DE DIAS E FOLGAS
const API_URL_BUSCA_FOLGAS_DISP = 'https://api-production-d9e6.up.railway.app/getFolgasDisp';//IDFUNC ATUALIZACAO DE DIAS E FOLGAS
const API_URL_LISTA_FUNC = 'https://api-production-d9e6.up.railway.app/funcionarios';//IDPOSTO
const API_URL_LISTA_SOLICITA_FOLGAS = 'https://api-production-d9e6.up.railway.app/buscaSolicitacaoFolga';//IDPOSTO
const API_URL_BUSCA_ID_POSTO = "https://api-production-d9e6.up.railway.app/getPostoPoridSemana"//IdSemana
const API_URL_ADDFUNCDIA = 'https://api-production-d9e6.up.railway.app/funcDispAtu';
const API_URL_REMOVEFUNCDIA = 'https://api-production-d9e6.up.railway.app/funcDispAtuAdd';
const API_URL_REMOVEFOLGA = 'https://api-production-d9e6.up.railway.app/funcFolgasAtu'
const API_URL_ADICIONAFOLGA = 'https://api-production-d9e6.up.railway.app/funcFolgasAtuAdd'
const API_URL_ADD_DIA_TRAB = 'https://api-production-d9e6.up.railway.app/calendario/addDiaTrab';
const API_RUL_ADD_FOLGA ='https://api-production-d9e6.up.railway.app/addFolgacalendario';
const API_URL_ATU_DIA = 'https://api-production-d9e6.up.railway.app/atualizar';
const API_URL_DEL_DIA_TRAB = 'https://api-production-d9e6.up.railway.app/calendario';
const API_URL_DEL_FOLGA = 'https://api-production-d9e6.up.railway.app/calendariodelfolga'
const API_URL_ATU_HOR = 'https://api-production-d9e6.up.railway.app/atualizarDiaTrabalhado';
const API_URL_BUSCA_SEMANA = 'https://api-production-d9e6.up.railway.app/retornadia';
const API_URL_ID_DIA_TRAB = 'https://api-production-d9e6.up.railway.app/diaTrabalhado'
const API_URL_ID_FOLGA = 'https://api-production-d9e6.up.railway.app/folga'
const API_URL_NOME_TRAB = 'https://api-production-d9e6.up.railway.app/NomeFunc'
const API_URL_TRABALADORES_PREENCHIDA = 'https://api-production-d9e6.up.railway.app/funcDoDia'
const API_URL_BUSCA_ID = 'https://api-production-d9e6.up.railway.app/funcsIDs'
const API_BUSCA_INFO_TRABALHADOR = 'https://api-production-d9e6.up.railway.app/funcsIDs'
const API_ADD_FALTA = "https://api-production-d9e6.up.railway.app/addFalta";
const API_RETURN_PAGE = "https://api-production-d9e6.up.railway.app/DeletaPorIdSemana"

const PaginaComQuadrados = () => {

  if(isDesktop){
    window.location.href = `/Desktop`;
  }



  function obterNomeDoDia(data) {
    // Dividir a string da data para extrair dia, mês e ano
    const [dia, mes, ano] = data.split('/').map(Number);
    
    // Criar um objeto Date a partir dos valores extraídos
    const dataObj = new Date(ano, mes - 1, dia); // Mês em JavaScript é 0-11, então subtraímos 1 do mês
    
    // Array com os nomes dos dias da semana
    const diasDaSemana = [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado'
    ];
    
    // Obter o índice do dia da semana (0-6) usando getDay()
    const indiceDoDia = dataObj.getDay();
    
    // Retornar o nome do dia da semana correspondente
    return diasDaSemana[indiceDoDia];
}
  
const url = new URL(window.location.href);
const token = url.searchParams.get('token');
const semanaId = url.searchParams.get("semanaId")

  const verificaLogin = async (token) => {
    const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
    try{
      const response = await axios.post(`${API_URL_Verify}`, {
        token: token
      })
      if(response.data != 200){
        setIdPosto(response.data.idPosto)
      } else {
        window.location.href = "/";
      }
    }catch(error){
      window.location.href = "/";
    }
  }
  const [idPosto, setIdPosto] = useState();




  const [quadrado1, setQuadrado1] = useState([]);
  const [quadrado2, setQuadrado2] = useState([]);
  const [quadrado1ID, setQuadrado1ID] = useState([]);
  const [quadrado2ID, setQuadrado2ID] = useState([]);
  const [preenchida, setPreenchida] = useState();
  const [atuListTrab, setAtuListTrab] = useState([]);
  const [trabalhadores, setTrabalhadores] = useState([]);
  const [listaIdsSolicitaFolga, setListaIdsSolicitaFolga] = useState([]);
  const [dataSalva, setDataSalva] = useState("");
  const [diaSemana, setDiaSemana] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  
  const [descricaoFalta, setDescricaoFalta] = useState("");

  const [botaoSeguranca, setBotaoSeguranca] = useState(false)

  const [preenhcidaBotaoDireita, setPreenhcidaBotaoDireita] = useState(false)

  const [isOpen, setIsOpen] = useState()
  const [horarios, setHorarios] = useState();


  function showAlertCancelAtu(string, mensagem, mensagem1) {
    var alertBox = document.createElement("div");
    alertBox.innerHTML = `
      <h2>${string}</h2>
      <p>${mensagem}</p>
      <p>${mensagem1}</p>
      <button id="cancel-btn">Cancelar</button>
    `;
    alertBox.style.position = "fixed";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.transform = "translate(-50%, -50%)";
    alertBox.style.backgroundColor = "white";
    alertBox.style.padding = "20px";
    alertBox.style.border = "1px solid #ccc";
    alertBox.style.borderRadius = "10px";
    alertBox.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.2)";
    alertBox.style.zIndex = "1000";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.margin = 'auto';
    alertBox.style.textAlign ='center';
    alertBox.style.width = "45%"
    document.body.appendChild(alertBox);


    var cancelBtn = document.getElementById("cancel-btn");
  
  
    cancelBtn.addEventListener("click", function() {
      document.body.removeChild(alertBox);
    });
  }


  function showAlertCancel(string, mensagem) {
    var alertBox = document.createElement("div");
    alertBox.innerHTML = `
      <h2>${string}</h2>
      <p>${mensagem}</p>
      <button id="cancel-btn">Cancelar</button>
    `;
    alertBox.style.position = "fixed";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.transform = "translate(-50%, -50%)";
    alertBox.style.backgroundColor = "white";
    alertBox.style.padding = "20px";
    alertBox.style.border = "1px solid #ccc";
    alertBox.style.borderRadius = "10px";
    alertBox.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.2)";
    alertBox.style.zIndex = "1000";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.margin = 'auto';
    alertBox.style.textAlign ='center';
  
    document.body.appendChild(alertBox);


    var cancelBtn = document.getElementById("cancel-btn");
  
  
    cancelBtn.addEventListener("click", function() {
      document.body.removeChild(alertBox);
    });
  }


  useEffect(() => {
    verificaLogin(token)
  }, [])

  useEffect(() => {

    const defineData = async () => {
      try{
        const responseData = await axios.get(`${API_URL_BUSCA_SEMANA}/${semanaId.toString()}/1`)//MUDAR SEMANA AO MUDAR COD
        if(responseData.data != 200){
          const data = responseData.data.Data
          setDataSalva(prevItem => data);
          const dia = obterNomeDoDia(data);
          setDiaSemana(prevItem => dia);
        } else {
          setDataSalva(prevItem => "");
          setDiaSemana(prevItem => "");
        }
      }catch(error){
        console.log("Erro em buscar data 61: "+ error)
      }
    }
    const defineListaFolga = async () =>{
      try{
        const responseData = await axios.get(`${API_URL_BUSCA_SEMANA}/${semanaId.toString()}/1`)//MUDAR SEMANA AO MUDAR COD
        if(responseData.data != 200){
          const data = responseData.data.Data
          const listaUNOOO = await axios.post(`${API_URL_LISTA_SOLICITA_FOLGAS}/${idPosto.toString()}`, {
            data: data,
            });
          if(listaUNOOO.data != 200){
            setListaIdsSolicitaFolga(prevItem => listaUNOOO.data);
          }
        } else {
          setListaIdsSolicitaFolga(prevItem => []);
        }
      }catch(error){
        console.log("Erro em buscar solicitacao Folgas 100: "+ error)
      }
    }
    defineData();
    defineListaFolga();
    
  },[idPosto])
  


  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response2 = await  axios.get(`${API_URL_BUSCA_SEMANA}/${semanaId.toString()}/1`)//MUDAR SEMANA AO MUDAR COD
        if(response2.data != 200){
          const preenchidares = response2.data.Preenchida;
          const data = response2.data.Data;
  
          setPreenhcidaBotaoDireita(prevItem => preenchidares)
          if (preenchidares == true) {
            
            setPreenchida(prevItem => true);
            setAtuListTrab(prevItem => true);
            const valores = response2.data.IdDiaTrabalhadoFunc
            const folgas = response2.data.Folga
  

  
            if(response2.data.IdDiaTrabalhadoFunc[0] != 0){
              setQuadrado1ID(prevItem => valores)
              const valoresString = valores.toString();
  
              const ids = valoresString.split(',');
              
              const idsNum = ids.map(id => parseInt(id));
  
              
                        // Usar Promise.all para esperar que todas as chamadas assíncronas sejam concluídas
  
              const resultadosAsync = await Promise.all(idsNum.map(async (id) => {
                try {
                  const respostaTrab = await axios.get(`${API_URL_ID_DIA_TRAB}/${id.toString()}`);
                  const respostaNome = respostaTrab.data.idFunc;
                  const respostanome = await axios.get(`${API_URL_NOME_TRAB}/${respostaNome.toString()}`);
  
                  return { id: respostanome.data.id ,Nome: respostanome.data.Nome, horas: [respostaTrab.data.horaDeEntrada, respostaTrab.data.horaDeSaida, respostaTrab.data.horaAlmoco], data: dataSalva };
                } catch (error) {
                  console.error('Erro ao buscar informações do trabalhador: ', error);
                  return null;
                }
              }));
              const resultadosFiltrados = resultadosAsync.filter(resultado => resultado != null);
              
              setQuadrado1( [...resultadosFiltrados]);
  
            }
  
            if(response2.data.Folga[0] != 0){
  
              setQuadrado2ID(prevItem => folgas)
              const folgasString = folgas.toString();
              const idsFolga = folgasString.split(',');
              const idNumFolgas = idsFolga.map(id => parseInt(id));
                const resultadosAsyncFolga = await Promise.all(idNumFolgas.map(async id =>{
                  try{
                    const respostaFolga = await axios.get(`${API_URL_ID_FOLGA}/${id.toString()}`)
                    const respostapNome = respostaFolga.data.idFunc;
                    const respostapnome = await axios.get(`${API_URL_NOME_TRAB}/${respostapNome.toString()}`);
                      return {id: respostapnome.data.id, Nome: respostapnome.data.Nome }
                  }catch(error){
                    console.error('Erro ao buscar folgas do trabalhador: ', error);
                  }
                }));
                const resultadosFiltradosFolga = resultadosAsyncFolga.filter(resultado => resultado !== null);
                setQuadrado2([...resultadosFiltradosFolga])
            }
  
          }
        }
        
  
      } catch (error) {
        console.error('Erro ao buscar variáveis da API: ', error);
      }
    };
    fetchData();

    
  }, [idPosto]);
  

    useEffect(() => {
      const fetchTrabalhadores = async () => {
        try {

          const response2 = await axios.get(`${API_URL_BUSCA_SEMANA}/${semanaId.toString()}/1`)//MUDAR SEMANA AO MUDAR COD
          if(response2.data != 200){
            const preenchidares = response2.data.Preenchida;
            if (preenchidares === true) {
              // Requisição para obter a lista de todos os funcionários
              const response = await axios.get(`${API_URL_LISTA_FUNC}/${idPosto.toString()}`);
              const listaFuncionarios = response.data; // Supondo que os dados estejam em um formato JSON
  
              // Requisição para obter a lista de IDs dos funcionários a serem removidos
              const response2 = await axios.get(`${API_URL_TRABALADORES_PREENCHIDA}/${semanaId.toString()}/1`);//MUDAR SEMANA AO MUDAR COD
              const idsParaRemover = response2.data; // Supondo que os dados estejam em um formato JSON
  
              // Loop sobre a lista de funcionários para filtrar os que precisam ser removidos
              const funcionariosFiltrados = listaFuncionarios.filter(funcionario => !idsParaRemover.includes(funcionario.id));
  
              // Loop sobre a lista filtrada para obter informações detalhadas de cada funcionário
              const informacoesDetalhadas = await Promise.all(funcionariosFiltrados.map(async funcionario => {
                  const responseDetalhes = await axios.get(`${API_URL_BUSCA_ID}/${funcionario.id}`);
                  return responseDetalhes.data; // Supondo que os dados estejam em um formato JSON
              }));
  
              // Agora, 'informacoesDetalhadas' contém as informações detalhadas dos funcionários filtrados
              setTrabalhadores(prevItem => informacoesDetalhadas);
            } else {
              const response = await axios.get(`${API_URL_LISTA_FUNC}/${idPosto.toString()}`);
              setTrabalhadores(prevItem => response.data);
            }
          }
        } catch (error) {
          console.error('Erro na requisição à API trab:', error);
        }
      };
  
    fetchTrabalhadores();
  }, [idPosto]);
  

  const [horariosModalVisible, setHorariosModalVisible] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState(null);

  const toggleModal = () => {

  }

  const handleCancel = () => {
    console.log("Usuário cancelou");
    // Aqui você pode adicionar o que deve acontecer quando o usuário cancelar
  };

  const botãoFalta = async (trabalhadorid) => {
    showAlert("Confirmação", "Você tem certeza que deseja atribuir falta? \nEssa ação não poderá ser alterada!" , trabalhadorid)
  }


  
  function showAlert(string, mensagem, trabalhadorid) {
    var alertBox = document.createElement("div");
    alertBox.innerHTML = `
      <h2>${string}</h2>
      <p>${mensagem}</p>
      <input placeholder="Descrição de Falta" id="desc"/>
      <button id="ok-btn">OK</button>
      <button id="cancel-btn">Cancelar</button>

    `;
    alertBox.style.position = "fixed";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.transform = "translate(-50%, -50%)";
    alertBox.style.backgroundColor = "white";
    alertBox.style.padding = "20px";
    alertBox.style.border = "1px solid #ccc";
    alertBox.style.borderRadius = "10px";
    alertBox.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.2)";
    alertBox.style.textAlign = 'center';
    alertBox.style.zIndex = "1000";
    alertBox.style.paddingBottom = "5";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.margin = 'auto';
  
    document.body.appendChild(alertBox);
  
    var okBtn = document.getElementById("ok-btn");
    var cancelBtn = document.getElementById("cancel-btn");
    var descric = document.getElementById("desc")


  
    okBtn.addEventListener("click", function() {
      
      const descString = descric.value;
      document.body.removeChild(alertBox);
      adicionaFalta(trabalhadorid, descString);
    });

  
    cancelBtn.addEventListener("click", function() {
      document.body.removeChild(alertBox);
    });
  }

  const adicionaFalta = async (trabalhadorid, descr) => {
    const trabalhador = {
      id : trabalhadorid
    }
    try{
      const reponse = await axios.post(`${API_ADD_FALTA}`, {
        idPosto : idPosto,
        idFunc : trabalhadorid,
        Data : dataSalva,
        numDia : 1,
        idSemana: semanaId,
        Descricao: descr.toString()
      })
      
      moverParaListaTrabalho(trabalhador);
      const response = await axios.put(`${API_URL_ADDFUNCDIA}/${trabalhadorid.toString()}`);

      botaoAjuste();

    }catch(error){
      console.error("Erro ao adicionar falta: ", error);
    }
  }



  const atualizahorAPI = async (trabalhadorid, hrEntrada, hrSaida, hrAlmoco) =>{
    try{
      const horaEntrada =hrEntrada.toString();
      const horaSaida = hrSaida.toString();
      const horaAlmoco = hrAlmoco.toString();
      const response = await axios.put(`${API_URL_ATU_HOR}/${semanaId}/1/${trabalhadorid}`,{ //MUDAR SEMANA AO MUDAR COD
        horaDeEntrada: horaEntrada,
        horaDeSaida: horaSaida,
        horaAlmoco : horaAlmoco
      });



    }catch(error){
      alert("Horários salvos!")
    }
  }

  const botaoAjuste = async () => {
    //Analisar se é uma boa opção
    const dateParts = dataSalva.split("/");
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);
    
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // +1 because getMonth() returns 0-11
    const currentYear = currentDate.getFullYear();
    
    if (year > currentYear || (year === currentYear && month > currentMonth) || (year === currentYear && month === currentMonth && day > currentDay)) {
      setPreenchida(false);
    } else if (year === currentYear && month === currentMonth && day === currentDay) {
      setPreenchida(false);
    } else {
      showAlertCancel("Data Excedida!", "A data atual é maior que a deste dia.")
    }


  };
  
  const navigate = useNavigate();
  const funcaoGoBack = async () => {
    if(botaoSeguranca == true){
      showAlertCancel("Bloqueio de Retorno", "O retorno da página foi bloqueado dado que nesta data os dias disponíveis e folgas de um funcionário foram atualizados. Salve a semana por motivos de segurança.")
    } else{
      try{
        const response2 = await  axios.get(`${API_URL_BUSCA_SEMANA}/${semanaId.toString()}/1`)//MUDAR SEMANA AO MUDAR COD
        let Preenc;
        if(response2.data != 200){
          if(preenchida == false){
            Preenc = false
          } else{
            Preenc = await response2.data.Preenchida
          }
        } else {
          Preenc = false
        }
        if(Preenc == true){
          window.location.href = `/Semanas?token=${token}`;
        } else{
          const response = await axios.post(`${API_RETURN_PAGE}`,{
            idSemana: semanaId
          })
          window.location.href = `/Semanas?token=${token}`;
        }
      }catch(error){
        console.log("Erro ao voltar a pagina.")
      }
    }

  
    
  }

  const funcaoBotaoDireita = async (id) => {
    // Lógica a ser executada quando o botão direito for pressionado
    // Adicione aqui o código que deseja executar
    for(let i =0;i<quadrado1.length;i++){
      if(quadrado1[i].horas.length != 3){
        alert("Horarios não definidos!")
        return null;
      }}
      if(preenchida == false) { //ver se é bom usar o indicador 'ou'
        try{
            const response = await axios.put(`${API_URL_ATU_DIA}/${semanaId.toString()}/1`,{ //MUDAR SEMANA AO MUDAR COD
              IdDiaTrabalhadoFunc : quadrado1ID,
              Folga: quadrado2ID
            });
          quadrado1.forEach((trabalhador)=>{
            atualizahorAPI(trabalhador.id,trabalhador.horas[0],trabalhador.horas[1], trabalhador.horas[2])
          })
          setAtuListTrab(prevItem => false);
        }catch(error){
          console.error("Erro ao atualizar dia: ", error)
        }
      } else if(preenhcidaBotaoDireita == false) {
        try{
            const response = await axios.put(`${API_URL_ATU_DIA}/${semanaId.toString()}/1`,{ //MUDAR SEMANA AO MUDAR COD
              IdDiaTrabalhadoFunc : quadrado1ID,
              Folga: quadrado2ID
            });
          quadrado1.forEach((trabalhador)=>{
            atualizahorAPI(trabalhador.id,trabalhador.horas[0],trabalhador.horas[1], trabalhador.horas[2])
          })
          setAtuListTrab(prevItem => false);
        }catch(error){
          console.error("Erro ao atualizar dia: ", error)
        }
      }

    
      window.location.href = `/PaginaQuad2?token=${token}&semanaId=${semanaId}`
    //return navigation.navigate('Terca', { semanaId: id, idPosto: idPosto});
  };

  const abrirModalHorarios = async (trabalhador) => {
    gerarHorarios()
    setSelectedWorker(trabalhador);

    setIsOpen(true)
  };

  const fecharModalHorarios = () => {
    setIsOpen(false)
  };

  const selecionarHorario = (horario) => {
    definirHoras(selectedWorker, horario);
    fecharModalHorarios();
  };



  const moverParaQuadrado1 = async (trabalhador) => {
    
    try{
      const response = await axios.get(`${API_URL_BUSCA_DIAS_DISP}/${trabalhador.id.toString()}`)
      if(response.data != 200){
        const responseDiaNum = response.data.DiasDisponiveis;
        if(responseDiaNum > 0){
          if(responseDiaNum == 1){
            showAlertCancelAtu("Atualização", "Os dias e folgas disponíveis desse funcionário serão atualizados." ,"*O botão de voltar para a página anterior será desativado por motivos de segurança.")
            setBotaoSeguranca(true)
          }
          setTrabalhadores(trabalhadores.filter((t) => t.id !== trabalhador.id));
          try{
            const response = await axios.post(`${API_URL_ADD_DIA_TRAB}`, {
              idFunc: trabalhador.id.toString(),
              horaDeEntrada: null,
              horaDeSaida: null,
              horaAlmoco: null,
              Data: dataSalva,
              numDia: 1, //MUDAR SEMANA AO MUDAR COD
              idSemana: semanaId,
              trabalhando: false,
              almocando: false
            })
            const novoDiaTrabId = response.data.id;
            setQuadrado1ID([...quadrado1ID, novoDiaTrabId]);
            setQuadrado1([...quadrado1, { ...trabalhador,id: trabalhador.id,  horas: [] , data: dataSalva}]);
            await axios.put(`${API_URL_ADDFUNCDIA}/${trabalhador.id.toString()}`);
          } catch(error){
            console.error("Erro ao mover para quadrad1: ", error)
          }
        } else {
          showAlertCancel("Alerta", "Este funcionário não tem dias disponiveis!")
        }
      }

    }catch (error){
      console.error("Erro na função moverParaQuadrado1: ", error)
    }

    

  };


  const moverParaQuadrado2 = async (trabalhador) => {
    try{
      const response = await axios.get(`${API_URL_BUSCA_FOLGAS_DISP}/${trabalhador.id.toString()}`)
      if(response.data != 200){
        const responseDiaNum = response.data.FolgasDisponiveis;
        if(responseDiaNum > 0 ){
          setTrabalhadores(trabalhadores.filter((t) => t.id !== trabalhador.id));
      try{
        const response = await axios.post(`${API_RUL_ADD_FOLGA}`, {
          idFunc: trabalhador.id.toString(),
          Data: dataSalva,
          numDia: 1,
          idSemana: semanaId,
        })
        const novaFolgaID = response.data.id;
        setQuadrado2ID([...quadrado2ID, novaFolgaID]);
        setQuadrado2([...quadrado2, {...trabalhador, id: trabalhador.id, Nome: trabalhador.Nome, data: dataSalva}]);
        await axios.put(`${API_URL_REMOVEFOLGA}/${trabalhador.id.toString()}`);
      } catch(error){
        console.error("Erro ao mover para quadrad22: ", error)
      }
        } else {
          showAlertCancel("Alerta", "Este funcionário não tem folgas disponiveis!" )
        }
      }

    }catch(error){
      console.error("Erro na função moverParaQuadrado2: ", error)
    }
    
  };

  const refresh = (index) => {
    // Crie uma cópia do array quadrado1
    const newArray = [...quadrado1];
    
    // Limpe as horas do item no índice fornecido
    newArray[index].horas = [];

    // Atualize o estado com o novo array
    setQuadrado1(newArray);
  };

  const moverParaLista = async (trabalhador) => {//MOVER PARA AS OUTRAS PAGINAS
    if (atuListTrab == true) {
      try {
        const respostanome = await axios.get(`${API_URL_NOME_TRAB}/${trabalhador.id}`);
        setTrabalhadores((prevTrab) => [...prevTrab, respostanome.data]);
        setQuadrado2((prevQuadrado2) =>
          prevQuadrado2.filter((t) => t.id !== trabalhador.id)
        );
        try {
          const del = await axios.delete(`${API_URL_DEL_FOLGA}/${semanaId.toString()}/1/${trabalhador.id}`);
          if (del.data) {
            const deleteItemId = del.data.id;
            setQuadrado2ID((prevQuadrado2ID)=> prevQuadrado2ID.filter((id)=> id !== deleteItemId));
            await axios.put(`${API_URL_ADICIONAFOLGA}/${trabalhador.id}`);
          } else {
            console.error("Erro ao excluir item: Resposta inválida da requisição DELETE(301)");//ERROO AQUI 
          }
        } catch (error) {
          console.log("Erro mover para lista (1)");
        }
      } catch (error) {
        console.error("Erro mover para lista (2)");
      }
    } else {
      if (!trabalhador || !semanaId) {
        console.error("Erro: Trabalhador inválido!");
        return;
      }
    
    setTrabalhadores((prevTrabalhadores) => [...prevTrabalhadores, trabalhador]);
    try {
      setQuadrado2((prevQuadrado2) =>
        prevQuadrado2.filter((t)=> t.id !== trabalhador.id)
      );
      const trabalhadorId = trabalhador.id !== undefined ? trabalhador.id.toString() : '';
      const del = await axios.delete(`${API_URL_DEL_FOLGA}/${semanaId.toString()}/1/${trabalhadorId}`);
      if (del.data && del.data.id) {
        const deleteItemId = del.data.id;
        setQuadrado2ID((prevQuadrado2ID) => prevQuadrado2ID.filter((id) => id !== deleteItemId));
        await axios.put(`${API_URL_ADICIONAFOLGA}/${trabalhador.id}`);
      } else {
        console.error("Erro ao excluir o item: Resposta inválida da requisição DELETE");
      }
    } catch (error) {
      console.error("Erro ao realizar operações assíncronas: ", error);
    }
  }
};
  


  const moverParaListaTrabalho = async (trabalhador) => {
    const dataAtual = new Date();



    //item.horas[0]
    //item.data

    if (atuListTrab === true) {
      try {
        const respostanome = await axios.get(`${API_URL_NOME_TRAB}/${trabalhador.id}`);
        // Adicione as informações do trabalhador ao estado trabalhadores
        setTrabalhadores((prevTrab) => [...prevTrab, respostanome.data]);
        setQuadrado1((prevQuadrado1) =>
          prevQuadrado1.filter((t) => t.id !== trabalhador.id)
        );
        try {
          const del = await axios.delete(`${API_URL_DEL_DIA_TRAB}/${semanaId.toString()}/1/${trabalhador.id}`); //MUDAR SEMANA AO MUDAR COD
          if (del.data && del.data.id) {
            const deleteItemId = del.data.id;
  
            // Removendo o ID da lista Quadrado1ID
            setQuadrado1ID((prevQuadrado1ID) => prevQuadrado1ID.filter((id) => id !== deleteItemId));
  
            // Realizando a requisição PUT para remover o funcionário do dia
            await axios.put(`${API_URL_REMOVEFUNCDIA}/${trabalhador.id}`);
          } else {
            console.error("Erro ao excluir o item: Resposta inválida da requisição DELETE");
          }
        } catch {
          console.log("Erro mover para listaTrabalho (1)");
        }
      } catch (error) {
        console.error("Erro mover para listaTrabalho (2)");
      }
    } else {
      if (!trabalhador || !semanaId) {
        console.error("Erro: trabalhador é undefined ou null");
        return;
      }

      const trabalhadorId = trabalhador.id !== undefined ? trabalhador.id.toString() : '';

  
      // Adicionando o trabalhador de volta à lista original
      setTrabalhadores((prevTrabalhadores) => [...prevTrabalhadores, trabalhador]);
  
      try {
        // Removendo o trabalhador da lista quadrado1
        setQuadrado1((prevQuadrado1) =>
          prevQuadrado1.filter((t) => t.id !== trabalhador.id)
        );
  
        // Ensure that trabalhador.id is defined before calling toString
        const trabalhadorId = trabalhador.id !== undefined ? trabalhador.id.toString() : '';
  
        // Realizando a requisição DELETE
        const del = await axios.delete(`${API_URL_DEL_DIA_TRAB}/${semanaId.toString()}/1/${trabalhadorId}`); //MUDAR SEMANA AO MUDAR COD
  
        if (del.data && del.data.id) {
          const deleteItemId = del.data.id;
  
          // Removendo o ID da lista Quadrado1ID
          setQuadrado1ID((prevQuadrado1ID) => prevQuadrado1ID.filter((id) => id !== deleteItemId));
  
          // Realizando a requisição PUT para remover o funcionário do dia
          await axios.put(`${API_URL_REMOVEFUNCDIA}/${trabalhadorId}`);
        } else {
          console.error("Erro ao excluir o item: Resposta inválida da requisição DELETE");
        }
      } catch (error) {
        console.error("Erro ao realizar operações assíncronas: ", error);
      }
    }
  };
  
  
  
  
  

  const definirHoras = (trabalhador, horario) => {
    setQuadrado1(
      quadrado1.map((t) =>
        t.id === trabalhador.id
          ? { ...t, horas: t.horas.length < 3 ? [...t.horas, horario] : [] }
          : t
      )
    );
  };

  const gerarHorarios = () => {
    const horarios = [];
    for (let hora = 0; hora < 24; hora++) {
      for (let minuto = 0; minuto < 60; minuto += 30) {
        const horario = `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`;
        horarios.push(horario);
      }
    }
    setHorarios(horarios)
    return horarios;
  };

//Arrumar os styles com verificação true false
  return (
    <div style={styles.container}>
        <div style={styles.nomeDiaLetreiro}>
            {preenchida ? (
                <div style={styles.botaoAjuste}>
                    <button style={styles.buttonAjust} onClick={() => botaoAjuste()}>
                        <p style={styles.textAjustar}>Ajustar</p>
                      </button>
                </div>
            ): (
                <div style={styles.botaoAjuste}>

                </div>
            )}
            <h3 style={styles.nomeDia}>{diaSemana}</h3>
            <h3 style={styles.nomeData}>{dataSalva}</h3>
            <div style={styles.botoesLateral}>
                <button style={styles.botaoRedondo} >
                    <img src={Left} style={styles.iconSeta} onClick={() => funcaoGoBack()}/>
                </button>
                <button style={styles.botaoRedondo1}  onClick={() => funcaoBotaoDireita(semanaId)}>
                    <img src={Right} style={styles.iconSeta} />
                </button>
            </div>
        </div>

            

            <div style={styles.quadrado}>
             <h5 style={styles.LetreiroFuncDia}>Funcionários do Dia</h5>
                <div style={styles.ListaFunc}>
                

                    {quadrado1.map((item, index) => (
                        
                        <div style={styles.itemMain}>
                            <p style={styles.NomeLetreiro}>{item.Nome}</p>
                            <div style={styles.itemQuadrado}>
                                <button style={styles.iconeRefreshContainer} onClick={() =>  preenchida ? toggleModal() : refresh(index)}>
                                    <div style={styles.refreshDiv}>
                                        <img src={Refresh} style={styles.refreshIcon} />
                                    </div>
                                   
                                </button>

                                <div style={styles.conteudoEsquerda}>
                                    <p style={styles.nomePadding}>Horário</p>
                                    <div style={styles.horariosmargin}>
                                        <p style={styles.horasDentroDoBloco}>{item.horas[0]} {item.horas[1]}</p>
                                    </div>
                                    <p style={styles.nomePadding}>Descanso</p>
                                    <div style={styles.horariosmargin}>
                                        <p style={styles.horasDentroDoBloco}>{item.horas[2]}</p>
                                    </div>
                                    
                                </div>
                                <div style={styles.conteudoDireita}>
                                    {preenchida ? (
                                        <div style={styles.containerFalta}>
                                            <button style={styles.botaoFalta} onClick={() => botãoFalta(item.id)}>
                                                <img src={esclama} style={styles.imgFalta}/>
                                            </button>
                                        </div>
                                    ): (
                                        <div style={styles.containerFalta} ></div>
                                    )}
                                    <div >
                                        <button style={ styles.botaoPadraoAlt } onClick={() => preenchida ? toggleModal() : abrirModalHorarios(item)}>
                                            <p>
                                                {item.horas.lenght >= 2 ? 'Escolher Almoço' : 'Escolher Horario'}
                                            </p>
                                        </button>
                                    </div>
                                    <div >
                                        <button style={ styles.botaoPadraoAlt }>
                                            <p style={styles.textoBotaoRetornarLista} onClick={() => preenchida ? toggleModal() : moverParaListaTrabalho(item)}>Retornar à Lista</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
                <div style={styles.quadradoLista}>
                    <h4 style={styles.funcDoDia}>Folgas</h4>
                    <div style={styles.listaFolga}>
                            {quadrado2ID.length != 0  ? (
                                quadrado2.map((item) => (
                                    <div style={styles.itemQuadradoFolgas}>
                                        <div >
                                            <p tyle={styles.nomeFolga}>{item.Nome}</p>
                                            <button style={styles.BotaoRetornarLista} onClick={() => preenchida ? toggleModal() : moverParaLista(item)}>
                                                <p style={styles.textoBotaoRetornarLista}>Retornar à Lista</p>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ):(
                                <div></div>
                            )}
                    </div>


                    <div style={styles.QuadradoTrab}>
                    <div style={styles.itemLista}>
                        {trabalhadores.map((trabalhador) => (
                            
                                <div style={styles.divTrab}>
                                    {trabalhador.DiasDisponiveis == 6 && trabalhador.FolgasDisponiveis == 1 ? (
                                        <p style={styles.nomeTrabRed}>{trabalhador.Nome}</p>
                                    ): (
                                        <p style={styles.nomeTrab}>{trabalhador.Nome}</p>
                                    )}
                                    

                                    <button style={styles.botaoTrabs} onClick={() => preenchida ? toggleModal() : moverParaQuadrado1(trabalhador)}>
                                        <div style={styles.rowContainer}>

                                            <p style={styles.textoBotao}>{trabalhador.DiasDisponiveis}</p>
                                        </div>
                                        <div style={styles.rowContainer}>
                                            <img src={green} style={styles.textoBotao}></img>
                                        </div>
                    
                                    </button>
                                    <button style={styles.botaoTrabs1} onClick={() => preenchida ? toggleModal() : moverParaQuadrado2(trabalhador)}>
                                        <div style={styles.rowContainer}>
                                            <p style={styles.textoBotao}>{trabalhador.FolgasDisponiveis}</p>
                                        </div>
                                        <div style={styles.rowContainer}>
                                            <img src={red} style={styles.textoBotao}></img>
                                        </div>
                                    </button>
                                </div>
                            
                        ))}
                        </div>
                    </div>

                    {isOpen ? (
                        <div style={styles.modalContainer}>
                            <div style={styles.modalContent}>
                                <h5>Escolha um Horário</h5>
                                <div style={styles.Lista}>
                                    {horarios.map((item) => (
                                        <button style={styles.ListaButton}  onClick={() => preenchida ? toggleModal() : selecionarHorario(item)}>
                                            <p>{item}</p>
                                        </button>
                                    ))}
                                </div>
                                <button style={styles.modalBotaoFechar}>
                                    <p  style={styles.textFechar} onClick={() => preenchida ? toggleModal() : fecharModalHorarios()}>Fechar</p>
                                </button>
                            </div>
                        </div>

                    ):(
                        <div></div>
                    )}
                </div>




    </div>
  )}

  const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      },
      nomeDiaLetreiro: {
        height: 100,
        textAlign: 'center'
      },
      botaoAjuste: {
        height: 25,
        marginTop: 10
      },
      buttonAjust: {
        height: 30,
        marginTop: 10
      },
      textAjustar: {
        fontSize: 15,
        marginTop: 3,
        fontWeight: 'bold',
      },
      nomeDia: {
        fontSize: 23,
        paddingTop: 10
      },
      nomeData: {
        fontSize: 20,
        marginTop: -17
      },
      botoesLateral: {
        display: 'flex',
        marginTop: -108,
        marginLeft: 51
      },
      botaoRedondo: {
        marginLeft: -60,
         background: 'none',
        border: 'none'
      },
      iconSeta:{
        height: 30,
        width: 30
      },
      botaoRedondo1: {
        marginLeft: 200,
        background: 'none',
        border: 'none',
      },
      quadrado:{
        width: '80%',
        height: '45%',
        backgroundColor: 'rgba(255,223,9,0.3)',
        borderRadius: 25,
        borderWidth: 1,
        borderColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 10,
        marginTop: 55,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
      },
      LetreiroFuncDia: {
        marginTop: 10,
        fontSize: 20,
        marginLeft: 80
      },
      ListaFunc: {
        height: 280,
        width: 335,
        overflowY: 'auto',
        marginTop: -30
      },
      itemMain: {
        height: "43%",
        width: "90%",
        backgroundColor: 'white',
        marginLeft: 25,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
        borderRadius: 10,
      },
      NomeLetreiro: {
        textAlign: 'center',
        fontWeight: 'bold',
      },
      itemQuadrado: {
        display: 'inline'
      },
      iconeRefreshContainer: {
        marginLeft: 10,
        background: 'none',
        
      },

      refreshIcon: {
        height: 20,
        width: 20,
      },
      conteudoEsquerda: {
        marginLeft: 100,
        marginTop: -50
      },
      nomePadding: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 13,
        marginLeft: -200,
        marginTop: 5
      },
      horariosmargin: {
        border: "2px solid gray",
        borderRadius: 10,
        height: 20,
        width: 100,
        marginTop: -10,
        marginLeft: -45,
        textAlign: 'center'
      },
      horasDentroDoBloco: {
        fontSize: 14,
        marginTop: 0

      },
      conteudoDireita: {
        marginLeft: 180,
        marginTop: -120
      },
      containerFalta: {
        display: 'flex',
        marginLeft: 90,
        background: 'none',
        border: 'none',
        height: 30,
        width: 30
      },
      botaoFalta: {
        background: 'none',
        border: 'none'

      },
      imgFalta: {
        height: 25,
        width: 25,
        
      },
      botaoPadraoAlt:{
        width: '95%',
        height: 40,
        backgroundColor: 'white',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 3,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
        fontSize: 10,
        fontWeight: 'bold',
      },
      quadradoLista: {
        width: '80%',
        height: '13.5%',
        backgroundColor: 'rgba(255,183,70,0.3)',
        borderRadius: 25,
        borderWidth: 1,
        borderColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 10,
        marginTop: 15,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
      },
      funcDoDia: {
        marginTop: 8,
        fontSize: 18,
        marginLeft: 139
      },
      listaFolga: {
        height: 55,
        width: 320,
        overflowY: 'auto',
        marginTop: -30,
        alignItems: 'center',
        textAlign: 'left'
      },
      itemQuadradoFolgas: {
        height: 45,
        marginLeft: 45,
        fontWeight: 'bold'
      },
      nomeFolga: {

      },
      BotaoRetornarLista: {
        background:'none',
        border: 'none',
        marginTop: -20,
        display: 'flex',
        marginLeft: -10,
        height: 20
      },
      textoBotaoRetornarLista: {
        color: 'red',
        fontSize: 10
      },
      QuadradoTrab:{
        width: '100%',
        height: '107%',
        backgroundColor: 'white',
        borderRadius: 25,
        borderWidth: 1,
        borderColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 10,
        marginTop: 55
      },
      itemLista: {
        height: "100%",
        width: 330,
        overflowY: 'auto',
        marginTop: "5vh",
      },
      divTrab: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        height: "25%",
        padding: 10,
        borderBottom: '1px solid ',
        marginLeft: 15
      },
      nomeTrabRed: {
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: -50,
        width: 150,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'red',
      },
      nomeTrab: {
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: -50,
        width: 150,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      botaoTrabs: {
        display: 'flex',
        backgroundColor: 'white',
        color: 'green',
        border: 'none',
        padding: 0,
        fontSize: 16,
        cursor: 'pointer',
        marginRight: 10,
        flexDirection: 'row',
        height: 35,
        width: 55,
        marginRight: -100

      },
      botaoTrabs1: {
        display: 'flex',
        backgroundColor: 'white',
        color: 'red',
        border: 'none',
        padding: 0,
        fontSize: 16,
        cursor: 'pointer',
        flexDirection: 'row',
        height: 35,
        width: 55,
        marginRight: -10
      },
      rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      textoBotao: {
        height: 25,
        width: 25,
        marginRight: 0,
        marginTop: 8

      },
      modalContainer: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      },
      modalContent: {
        backgroundColor: '#fff',
        padding: 15,
        borderRadius: 10,
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        width: 200,
        textAlign: 'center'
      },
      Lista: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflowY: 'auto',
        height: 250,
        width: 200
      },
      ListaButton: {
        margin: 10,
        padding: 10,
        border: 'none',
        borderRadius: 10,
        backgroundColor: '#eee',
        cursor: 'pointer',
        
      },
      ListaButtonHover: {
        backgroundColor: '#ddd'
      },
      modalBotaoFechar: {
        backgroundColor: '#333',
        color: '#fff',
        padding: 10,
        border: 'none',
        borderRadius: 10,
        cursor: 'pointer',
        marginTop: 15,
        width: 100,
        height: 40,

      },
      textFechar: {
        height: 30,
        width: 40,
        fontSize: 12,
        paddingLeft: 20,
      },
      modalBotaoFecharHover: {
        backgroundColor: '#444',

      }

  }

export default PaginaComQuadrados;
