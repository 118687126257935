import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Flecha from './icons/Flecha.png'


import axios from 'axios';


import { isDesktop } from 'react-device-detect';





const MyCalendarPage1 = () => {

  if(isDesktop){
    window.location.href = `/Desktop`;
  }
    const API_URL_BUSCA_FUNC = "https://api-production-d9e6.up.railway.app/NomeFunc"//IFUNC
    const API_URL_BUSCA_DIAS = "https://api-production-d9e6.up.railway.app/buscaDiasRegistrados"; //IDFUNC
    const API_URL_BUSCA_FOLGAS = "https://api-production-d9e6.up.railway.app/buscaFolgasRegistrados"; //IDFUNC
    const API_URL_BUSCAHORARIO = "https://api-production-d9e6.up.railway.app/buscaHorariosPorData"; //IDFUNC
    const API_SOLICITA_FOLGA = "https://api-production-d9e6.up.railway.app/SolicitaFolga"; //IDFUNC
    const API_URL_BUSCA_FALTAS ="https://api-production-d9e6.up.railway.app/buscaFaltasRegistrados"; //IDFUNC
    const API_URL_BUSCA_FALTA_E_FOLGAS_DATA = "https://api-production-d9e6.up.railway.app/buscaFaltaPorData"; //IDFUNC
    

    const [idFunc, setIdFuncPag] = useState();
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const Func = url.searchParams.get('Func')
  
  
  
    const verificaLogin = async (token) => {
      const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
      try{
        const response = await axios.post(`${API_URL_Verify}`, {
          token: token
        })
        if(response.data != 200){
          setTime(true)
          setIdFuncPag(Func)
        } else {
          window.location.href = "/";
        }
      }catch(error){
        window.location.href = "/";
      }
    }



    const eventos = [
        { title: 'Evento 1', start: '2023-03-01', backgroundColor: 'rgba(217,0,0,0.5)' },//vermelho
        { title: 'Evento 2', start: '2023-03-02', backgroundColor: 'rgba(0,74,222,0.5)' },//azul
        { title: 'Evento 3', start: '2023-03-03', backgroundColor: 'rgba(41,203,0,0.5)' },//verde
        { title: 'Evento 3', start: '2023-03-04', backgroundColor: 'rgba(41,203,0,0.5)' },
        { title: 'Evento 3', start: '2023-03-06', backgroundColor: 'rgba(41,203,0,0.5)' },
        { title: 'Evento 3', start: '2023-03-07', backgroundColor: 'rgba(41,203,0,0.5)' },

      ];

      const handleDateClick = (arg) => {
        console.log('Date clicked:', arg.dateStr);
        alert(`Você clicou no dia ${arg.dateStr}!`);
      };


    


  const [markedDates, setMarkedDates] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [horaEntrada, setHoraEntrada] = useState('');
  const [horaAlmoco, setHoraAlmoco] = useState('');
  const [horaSaida, setHoraSaida] = useState('');
  const [status, setStatus] = useState("");
  const [cor, setCor] = useState("");
  const [api1Dates, setApi1Dates] = useState([]);
  const [api2Dates, setApi2Dates] = useState([]);  
  const [api3Dates, setApi3Dates] = useState([]);
  const [listaTodos, setListaTodos] = useState([])

  const [nomeFunc, setNomeFunc] = useState();

  const [func1, setFunc1] = useState(false);
  const [func2, setFunc2] = useState(false);
  const [func3, setFunc3] = useState(false);
  const [time, setTime] = useState(false)


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSend = () => {
    // send the selected date to your server or perform any other action
    console.log(selectedDate);
  };

  useEffect( () => {
    const funcNome = async () => {
      try{
        const modeloImg = await axios.get(`${API_URL_BUSCA_FUNC}/${idFunc.toString()}`)
        if(modeloImg.data != 200){
  
          setNomeFunc(prevItem => modeloImg.data.Nome)
        }else {
  
          setNomeFunc(prevItem => "")
        }
      }catch(error){
        console.log("Erro ao buscar nome func")
      }
    }
    funcNome()

    
  })



  useEffect( () => {
    verificaLogin(token)
    const fetchDatas = async () => {
      try {
        const response = await axios.get(`${API_URL_BUSCA_DIAS}/${idFunc.toString()}`);//IDFUNC
        if(response.data != 200){
          const resp = response.data.Datas;
          const APIDates = resp.split(",");
          setApi1Dates(prevItem => APIDates);
        } else {
          setApi1Dates(prevItem => []);
        }



      } catch (error) {
        console.error("Erro ao buscar Datas: ", error);
      }
    };
  
    const fetchFolgas = async () => {
      try {
        const response = await axios.get(`${API_URL_BUSCA_FOLGAS}/${idFunc.toString()}`);//IDFUNC
        if(response.data != 200){
          const resp = response.data.Datas;
          const APIDates = resp.split(",");
          setApi2Dates(prevItem => APIDates);
        } else {
          setApi2Dates(prevItem => []);
        }
        
      } catch (error) {
        console.error("Erro ao buscar Folgas: ", error);
      }
    };

    const fetchFaltas = async () => {
      try{
        const response = await axios.get(`${API_URL_BUSCA_FALTAS}/${idFunc.toString()}`);//IDFUNC
        if(response.data != 200){
          const resp = response.data.Datas;
          const APIDates = resp.split(",");
          setApi3Dates(prevItem => APIDates);
        }else{
          setApi3Dates(prevItem => []);
        }
        
      }catch (error) {
        console.error("Erro ao buscar Faltas: ", error);
      }
    };


    const adicionaTodos = () => {
        const lista = []
        const datasFormatted = api1Dates.map((data) => {
            const [dia, mes, ano] = data.split("/");
            return { start: `${ano}-${mes}-${dia}`, backgroundColor: 'rgba(41,203,0,0.5)' };
          });
          lista.push(...datasFormatted)

          const datasFormatted2 = api2Dates.map((data) => {
            const [dia, mes, ano] = data.split("/");
            return { start: `${ano}-${mes}-${dia}`, backgroundColor: 'rgba(0,74,222,0.5)' };
          });
          lista.push(...datasFormatted2)
          const datasFormatted3 = api3Dates.map((data) => {
            const [dia, mes, ano] = data.split("/");
            return { start: `${ano}-${mes}-${dia}`, backgroundColor: "rgba(217,0,0,0.5)" };
          });
          lista.push(...datasFormatted3)


          setListaTodos(lista);
    }
    
    async function executeFunctions() {
        await fetchFaltas();
        setFunc1(true);
        await fetchDatas();
        setFunc2(true);
        await fetchFolgas();
        setFunc3(true)

        if(func1 && func2 && func3){
            adicionaTodos();
        }
    }   

    if(time == true){
      executeFunctions()
    }
  


    
    

    
  }, [func1, func2, func3, idFunc]);

  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format('DD/MM/YYYY');
  };

  const fetchHorarios = async (selectedDate) => { // Adiciona selectedDate como parâmetro
    try {
      
      // Formata a data no formato esperado pelo servidor (DD/MM/YYYY)
      //const selectedDateString = selectedDate.split('-').reverse().join('/');
        const selectedDateString = formatDate(selectedDate)

      // Faz a requisição para buscar os horários com a data formatada
      const modelo1 = await axios.post(`${API_URL_BUSCAHORARIO}/${idFunc.toString()}`, {//IDFUNC
          DataSelecionada: selectedDateString
      });

      
      if(modelo1.data != 200){
                // Atualiza os horários com os dados obtidos da API
                setHoraEntrada(prevItem => modelo1.data.diaTrab.horaDeEntrada);
                setHoraAlmoco(prevItem => modelo1.data.diaTrab.horaAlmoco);
                setHoraSaida(prevItem => modelo1.data.diaTrab.horaDeSaida);

                
                if(modelo1.data.diaTrab.TrabalhoDiaConcluido == true){
                  setStatus(prevItem => "CONCLUIDO")
                  setCor(prevItem => true)
                } else{
                  setStatus(prevItem => "NÃO CONCLUIDO");
                  setCor(prevItem => false)
                }
      }

      if (modelo1.data == 200) {
        const modelo2 = await axios.post(`${API_URL_BUSCA_FALTA_E_FOLGAS_DATA}/${idFunc.toString()}`,{
          DataSelecionada: selectedDateString
        })

        if(modelo2.data != 200){

          if(modelo2.data.falta == true ){
            setHoraEntrada(prevItem => "00:00");
            setHoraAlmoco(prevItem => "00:00");
            setHoraSaida(prevItem => "00:00");
            setStatus(prevItem => "FALTA")
            setCor(prevItem => false)
          } if(modelo2.data.Folga == true){
            setHoraEntrada(prevItem => "00:00");
            setHoraAlmoco(prevItem => "00:00");
            setHoraSaida(prevItem => "00:00");
            setStatus(prevItem => "FOLGA")
            setCor(prevItem => true)
          }else {
             // Define os horários como "00:00" se o dia trabalhado não for encontrado
          setHoraEntrada(prevItem => "00:00");
          setHoraAlmoco(prevItem => "00:00");
          setHoraSaida(prevItem => "00:00");

          }
        }           
        } else {
          console.error("Erro ao buscar Horários: ");
        }
    }catch(error){
        console.log("Erro ao buscar infos!")
    }
  };

  /*
  useEffect(() => {

    const fetchHorarios = async (selectedDate1) => { // Adiciona selectedDate como parâmetro
      try {
        const selectedDate = selectedDate1.date;
        // Formata a data no formato esperado pelo servidor (DD/MM/YYYY)
        const selectedDateString = selectedDate.split('-').reverse().join('/');

        // Faz a requisição para buscar os horários com a data formatada
        const modelo1 = await axios.post(`${API_URL_BUSCAHORARIO}/${idFunc.toString()}`, {//IDFUNC
            DataSelecionada: selectedDateString
        });
        
        if(modelo1.data != 200){
                  // Atualiza os horários com os dados obtidos da API
                  setHoraEntrada(prevItem => modelo1.data.diaTrab.horaDeEntrada);
                  setHoraAlmoco(prevItem => modelo1.data.diaTrab.horaAlmoco);
                  setHoraSaida(prevItem => modelo1.data.diaTrab.horaDeSaida);
                  
                  if(modelo1.data.diaTrab.TrabalhoDiaConcluido == true){
                    setStatus(prevItem => "CONCLUIDO")
                    setCor(prevItem => true)
                  } else{
                    setStatus(prevItem => "NÃO CONCLUIDO");
                    setCor(prevItem => false)
                  }
        }

            

      } catch (error) {
        if (error.response && error.response.data.error === "Dia Trabalhado não encontrado") {
          const selectedDateString = selectedDate.split('-').reverse().join('/');
          const modelo2 = await axios.post(`${API_URL_BUSCA_FALTA_E_FOLGAS_DATA}/${idFunc.toString()}`,{
            DataSelecionada: selectedDateString
          })
          if(modelo2.data != 200){
            if(modelo2.data.falta == true ){
              setHoraEntrada(prevItem => "00:00");
              setHoraAlmoco(prevItem => "00:00");
              setHoraSaida(prevItem => "00:00");
              setStatus(prevItem => "FALTA")
              setCor(prevItem => false)
            } if(modelo2.data.Folga == true){
              setHoraEntrada(prevItem => "00:00");
              setHoraAlmoco(prevItem => "00:00");
              setHoraSaida(prevItem => "00:00");
              setStatus(prevItem => "FOLGA")
              setCor(prevItem => true)
            }else {
               // Define os horários como "00:00" se o dia trabalhado não for encontrado
            setHoraEntrada(prevItem => "00:00");
            setHoraAlmoco(prevItem => "00:00");
            setHoraSaida(prevItem => "00:00");
            }
          }           
          } else {
            console.error("Erro ao buscar Horários: ", error);
          }
      }
    };
  
    // Verifica se há uma data selecionada antes de fazer a busca dos horários
    if (selectedDate) {
      fetchHorarios(selectedDate); // Chama fetchHorarios passando selectedDate como argumento
    }
  }, [selectedDate]);
  */

  const SolicitaFolga = async () => {
    try {
      const selectedDateString = selectedDate.split('-').reverse().join('/');
      const modelo1 = await axios.post(`${API_SOLICITA_FOLGA}/${idFunc.toString()}`, {
        Data: selectedDateString
      });

        // Verifica se a resposta indica que a folga foi solicitada com sucesso
        if (modelo1.data === "Folga Solicitada") {
          alert("Folga solicitada")
        } else if (modelo1.data === "Dia Já Criado") {
          alert("Folga ja solicitada para este dia")
        } else {
          // Outra resposta não esperada, imprime a resposta completa
          console.log("Resposta inesperada:", modelo1.data);
        }

    } catch (error) {
      // Trata o erro, imprimindo o erro completo para depuração
      console.error("Erro ao solicitar folga:", error);
      alert("Erro ao solicitar folga. Verifique a conexão com a internet e tente novamente.");
    }
  };
  


 
/*
  const fetchDatesFromAPIs = () => {
    const formattedDates = {};
    
    const processDates = (dates, dotColor) => {
      dates.forEach(date => {
        if (date && typeof date === 'string') {
          const [day, month, year] = date.split('/');
          if (day && month && year) {
            const dateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            if (formattedDates[dateString]) {
              formattedDates[dateString] = {
                ...formattedDates[dateString],
                dotColor
              };
            } else {
              formattedDates[dateString] = { marked: true, dotColor };
            }
          }
        }
      });
    };
  
    processDates(api1Dates, 'green');
    processDates(api2Dates, 'orange');
    processDates(api3Dates, 'red');
  
    // Mark the selected day with a blue dot
    if (selectedDate) {
      formattedDates[selectedDate] = { selected: true, selectedColor: 'blue' };
    }
  
    setMarkedDates(formattedDates);
  };
 

  useEffect(() => {
    fetchDatesFromAPIs();
  }, [selectedDate]);
  */

  const handleDayPress = (day) => {
    setSelectedDate(day.dateString);
  };

  const handleVoltar = () =>{
    window.location.href = `/PagFunc?token=${token}`;
  };


  return (
    <div style={styles.topContainer}>
      <button style={styles.botaoVoltar} onClick={handleVoltar}>
        <img src={Flecha} style={styles.IconeSelecionado}/>
      </button>
      <h2 style={styles.NomeFunc}>{nomeFunc}</h2>
      <div style={styles.calendario}>

    <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={listaTodos}
        dateClick={handleDateClick}
        eventDisplay="block"
        eventContent={(eventInfo) => {
            return (
            <div style={styles.dia}>
                
                <button
                    style={{
                        width: 56,
                        height: 15,
                        backgroundColor: eventInfo.event.extendedProps.backgroundColor,
                        background: 'none',
                        border: 'none'
                        
                    }}
                    onClick={() => fetchHorarios(eventInfo.event.start)}
                >
                </button>
            </div>
            );
        }}
        />
        </div>
        
            

        <div style={styles.infoContainer}>
            <div style={styles.viewHoras}>
                <h3 style={styles.infoText}>Hora de Entrada: {horaEntrada}</h3>
            </div>
            <div style={styles.viewHoras}>
                <h3 style={styles.infoText}>Hora do Almoço: {horaAlmoco}</h3>
            </div>
            <div style={styles.viewHoras}>
                <h3 style={styles.infoText}>Hora de Saída: {horaSaida}</h3>
            </div>
            <div style={styles.viewHoras}>
                <h3 style={styles.infoText}>Status:</h3> {cor ? (<p style={styles.infoTextGreen}>{status}</p>): (<p style={styles.infoTextRed}>{status}</p>)}
            </div>
        </div>

        </div>
      );
    
};

const styles ={
  botaoVoltar: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    zIndex: 1,
    height: 35,
    width : 35
  },
  NomeFunc: {
    textAlign: 'center'
  },
  IconeSelecionado:{
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    height: 35,
    width: 35,
    marginTop: 0,
    zIndex: 1,
    position: 'absolute',
    marginLeft: -15
  },
  calendario: {
    marginTop: 15,
    width: "94%",
    marginLeft: 12
  },
    dia:{
        height: 7,
    },
    infoContainer: {
        marginTop: 10,
        padding: 10,
        borderRadius: 5,
        marginLeft: 45
        
    },
    viewHoras:{
        display: 'flex',
        width: '88%',
        height: 39,
        flexDirection: 'row',
        backgroundColor: '#fff',
        borderRadius: 6,
        fontSize: 16,
        color: '#333',
        marginBottom: 10,
        paddingTop: 10,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
    },
    infoText: {
        fontSize: 16,
        height: 24,
        marginTop: 1
    },
    infoTextGreen:{
        fontSize: 16,
        height: 24,
        marginTop: 1,
        color: 'green',
        fontWeight: 'bold',
        marginLeft: 10
    },
    infoTextRed: {
        fontSize: 16,
        height: 24,
        marginTop: 1,
        color: 'red',
        fontWeight: 'bold',
        marginLeft: 10
    },
    botao: {
      marginTop: -5,
      marginLeft: 151,
      height: 35,
      width: 100,
    },
    popup: {
      position : 'absolute',
      top: "50%",
      left: "50%",
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      padding: 20,
      border: '1px solid #ddd',
      borderRadius: 10,
      boxShadow: "0 0 10px rgba(0,0,0, 0.2)"
    },


};

export default MyCalendarPage1;
