import React, {useState, useEffect} from "react";
import SpiralProgressBar from "./SpiralProgressBar";
import axios from "axios";
import moment from 'moment';
import caledario from "../icons/calendario.png"
import information from "../icons/information.png"
import config from "../icons/Config.png"
import gif from '../icons/loading.gif'
import Logo from "../../images/Logo.png"



import { isDesktop } from 'react-device-detect';

const CustomPage = () => {
  if(isDesktop){
    window.location.href = `/Desktop`;
  }


  function showAlertCancel(string, mensagem) {
    var alertBox = document.createElement("div");
    alertBox.innerHTML = `
      <h2>${string}</h2>
      <p>${mensagem}</p>
      <button id="cancel-btn">Cancelar</button>
    `;
    alertBox.style.position = "fixed";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.transform = "translate(-50%, -50%)";
    alertBox.style.backgroundColor = "white";
    alertBox.style.padding = "20px";
    alertBox.style.border = "1px solid #ccc";
    alertBox.style.borderRadius = "10px";
    alertBox.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.2)";
    alertBox.style.zIndex = "1000";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.margin = 'auto';
    alertBox.style.textAlign ='center';
  
    document.body.appendChild(alertBox);


    var cancelBtn = document.getElementById("cancel-btn");
  
  
    cancelBtn.addEventListener("click", function() {
      document.body.removeChild(alertBox);
    });
  }

  
    function convertMinutesToTime(minutes) {
      // Calcula a quantidade de horas
      let hours = Math.floor(minutes / 60);
      // Calcula os minutos restantes
      let remainingMinutes = minutes % 60;

      // Adiciona um zero à esquerda se o valor for menor que 10
      let hoursFormatted = hours < 10 ? '0' + hours : hours;
      let minutesFormatted = remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

      // Retorna a string no formato HH:mm
      return `${hoursFormatted}:${minutesFormatted}`;
  }

  function convertTime(apiTime) {
    // Obtenha a data atual
    const currentDate = new Date();

    // Extraia as partes da data atual
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Os meses começam do 0
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Extraia as horas e minutos do horário recebido da API
    const [hours, minutes] = apiTime.split(':');

    // Crie a string no formato desejado
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;

    return formattedDate;
  }
    // Function to calculate distance using the Haversine formula
    function calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Raio da Terra em quilômetros
    
      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);
    
      const lat1Rad = toRadians(lat1);
      const lat2Rad = toRadians(lat2);
    
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
    
      return distance;
    }
    
    function toRadians(deg) {
      return deg * (Math.PI / 180);
    }

    const getTimeInSaoPaulo = () => {
      const date = new Date();
      const options = { timeZone: 'America/Sao_Paulo', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      return new Intl.DateTimeFormat('pt-BR', options).format(date);
    };//10

    function retornaDia(num){
      switch (num) {
        case 0:
          return "Domingo";
        case 1:
          return "Segunda-Feira";
        case 2:
          return "Terca-Feira";
        case 3: 
          return "Quarta-Feira";
        case 4:
          return "Quinta-Feira";
        case 5:
          return "Sexta-Feira";
        case 6:
          return "Sabado";
        default:
          return "Dia inválido";
      }
    }

    function calcularDiferencaDeTempo(horaInicio, minutoInicio, horaFim, minutoFim) {
      const segundosPorHora = 3600;
      const segundosPorMinuto = 60;
    
      // Converter os horários para segundos
      let segundosInicio = horaInicio * segundosPorHora + minutoInicio * segundosPorMinuto;
      let segundosFim = horaFim * segundosPorHora + minutoFim * segundosPorMinuto;
    
      // Verificar se a hora de término é anterior à hora de início (considerando o intervalo de 24 horas)
      if (segundosFim < segundosInicio) {
        segundosFim += 24 * segundosPorHora;
      }
    
      // Calcular a diferença em segundos
      let diferencaSegundos = segundosFim - segundosInicio;
    
      return diferencaSegundos;
    }


    useEffect(() => {
      altEntraPagina();
    }, [])
  
    const [entraPagina, setEntraPagina] = useState();
  
  
    const altEntraPagina = () => {
      if (entraPagina == true) {
        setEntraPagina(false)
      } else {
        setEntraPagina(true);
      }
    }

    const [idPosto, setIdPosto] = useState();
    const [idFuncPag, setIdFuncPag] = useState();
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
  
  
  
    const verificaLogin = async (token) => {
      const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
      try{
        const response = await axios.post(`${API_URL_Verify}`, {
          token: token
        })
        if(response.data != 200){
          setIdPosto(response.data.idPosto)
          setIdFuncPag(response.data.id)
        } else {
          window.location.href = "/";
        }
      }catch(error){
        window.location.href = "/";
      }
    }


      function formatarHorario(momentObj) {
        if (!momentObj || !momentObj.isValid()) {
          throw new Error("Objeto Moment inválido");
        }
        return momentObj.format('HH:mm');
      }

      function montaSeMenor10(num) {
        if(num<10){
          return "0"+num;
        }else{
          return num
        }
      }

      function formatDate(dateString) {
        const parts = dateString.split("/");
        return `${parts[0]}/${parts[1]}`;
    }

    const agora = new Date();
    const horaAtual = agora.getHours();
    const horaAtualGet = montaSeMenor10(horaAtual);
    const minutosAtual = agora.getMinutes();
    const minutosAtualGet = montaSeMenor10(minutosAtual)
    const horaGet = `${horaAtualGet}:${minutosAtualGet}`

    const segundosFunction = (resp) => {
      if(resp == true) {
        setVarLetreiro(true)
      } else {
        setLetreiroSaida(false)
      }
    }


    const dia = agora.getDay();
    const day = agora.getDate();
    const month = agora.getMonth() +1;
    const monthGet = montaSeMenor10(month)
    const dayGet = montaSeMenor10(day)
    const year = agora.getUTCFullYear();
    const dataNow = `${dayGet}/${monthGet}/${year}`


    //Variaveis e API
    const API_URL_BUSCA_FUNC = "https://api-production-d9e6.up.railway.app/NomeFunc"//IFUNC
    const API_URL_BUSCA_NUM_GERENTE = "https://api-production-d9e6.up.railway.app/getNumGerente";//IDPOSTO
    const API_URL_BUSCA_DIA_TRAB = "https://api-production-d9e6.up.railway.app/buscaDiaTrabalho"//IDFUNC
    const API_URL_HORA_DEFINT_ALMOCO= 'https://api-production-d9e6.up.railway.app/atuHrEfAlm';//IDFUNC
    const API_URL_HORA_DEFINT_SAIDA = 'https://api-production-d9e6.up.railway.app/atuHrEfSaid';//IDFUNC
    const API_URL_HORA_DEFINT_ENTRADA = 'https://api-production-d9e6.up.railway.app/atuHrEfEnt';//IDFUNC
    const API_URL_CONCLUI_TRAB_ANT_ALM = 'https://api-production-d9e6.up.railway.app/atuDiaTrabAntAlmConclu';//IDFUNC
    const API_URL_CONCLUI_TRAB_DEP_ALM = 'https://api-production-d9e6.up.railway.app/atuDiaTrabDepAlmConclu';//IDFUNC
    const API_URL_CONCLUI_ALM = 'https://api-production-d9e6.up.railway.app/atuAlmConclu';//IDFUNC
    const API_URL_CONCLUI_TRAB_DIA = 'https://api-production-d9e6.up.railway.app/trabalhoDiaConclu'; //IDFUNC
    const API_URL_CONCLU_TRAB_POS_ALM = "https://api-production-d9e6.up.railway.app/atuDiaTrabDepAlmConclu";//IDFUNC
    const API_URL_RECEBE_TRABALHANDO = "https://api-production-d9e6.up.railway.app/atuTrabalhando";//IDFUNC
    const API_URL_BUSCA_TRABALHANDO = "https://api-production-d9e6.up.railway.app/buscaTrabalhando";//IDFUNC
    const API_URL_BUSCA_POSTO = "https://api-production-d9e6.up.railway.app/buscaPostoPorID"; //IDFUNC
    const API_URL_BUSCA_ALMOCANDO = "https://api-production-d9e6.up.railway.app/atuAlmocando"; //IDFUNC
    const API_URL_VERIFICA_ALMOCANDO = "https://api-production-d9e6.up.railway.app/buscaAlmocando"; //IDFUNC
    const API_ULR_BUSCA_HORAEFTENTRADA_BOL = "https://api-production-d9e6.up.railway.app/buscaHoraEfetivaEntrada"; //IDFUNC

        //Variaveis do Posto
        const [tempoDeAlmoco, setTempoDeAlmoco] = useState("");//Tempo Em Minutos
        const [tempoDeAlmocoFormatoAPI, setTempoDeAlmocoFormatoAPI] = useState("");//Tempo em Formato (HH:mm) VQA
    
        //Hora saida de Almoco
        const [horaSaidaAlmoco, setHoraSaidaAlmoco] = useState("");
    
        //Variaveis da API
        const [id,setId] = useState("")
        const [idFunc, setIdFunc] = useState("")
        const [trabalhoAntAlmConcluido, setTrabalhoAntAlmConcluido] = useState(false);
        const [trabalhoDepAlmConcluido, setTrabalhoDepAlmConcluido] = useState(false);
        const [almConcluido, setAlmConcluido] = useState(false);
        const [TrabalhoDiaConcluido, setTrabalhoDiaConcluido] = useState(false);
        const [horaDeEntrada, setHoraDeEntrada] = useState(":");
        const [horaEfetivaDeEntrada, sethoraEfetivaDeEntrada] = useState(false);
        const [horaDeSaida, setHoraDeSaida] = useState(":");
        const [horaEfetivaDeSaida , setHoraEfetivaDeSaida] = useState(false);
        const [horaDeAlmoco, setHoraAlmoco] = useState(":");
        const [horaEfetivaDeAlmoco, setHoraEfetivaDeAlmoco] = useState(false);
        const [Data, setData] = useState("");
        const [numDia, setNumDia] = useState("");
        const [idSemana, setIdSemana] = useState("");
        const [trabalhandoAPI, setTrabalhandoAPI] = useState(false);
        const [dataFormato, setDataFormato] = useState("");
        const [almocando, setAlmocando] =  useState(false);
        const [alegou,setAlegou] = useState();
    
        const [imagemFunc, setImagemFunc] = useState("");
        const [nomeFunc, setNomeFunc] = useState("");
    
        //Variaveis Letreiro
        const [letreiroSaida, setLetreiroSaida] = useState(false);
        const [horarioDoLetreiro, setHorarioDoLetreiro] = useState("");
        const [corLetreiro, setCorLetreiro] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [varLetreiro, setVarLetreiro] = useState("");
    
        const [trabalhoFinalizado, setTrabalhoFinalizado] = useState(false);
        
        
    
    
        //Variaveis API do progessBar
        const [horaEntradaFormato, setHoraEntradaFormato] = useState("");
        const [horaAlmocoFormato, setHoraAlmocoFormato] = useState("");
        const [horaSaidaAlmocoFormato, setHoraSaidaAlmocoFormato] = useState("");
        const [horaSaidaFormato, setHoraSaidaFormato] = useState("");
        const [resultadoVerificarHorario, setResultadoVerificarHorario] = useState(false);


        const [horaSaidaFormatoProgress, sethoraSaidaFormatoProgress] = useState("");
    
    
    

      
    
      
      const [horaEntrada, minutoEntrada] = horaDeEntrada.split(":");
      const [horaAlmoco, minutoAlmoco] = horaDeAlmoco.split(":")
      const [horaSaida, minutoSaida] = horaDeSaida.split(":")
    
      const [progress, setProgress] = useState(0);
      const [counting, setCounting] = useState(false);
      const [elapsedSeconds, setElapsedSeconds] = useState(0);
      const [currentTime, setCurrentTime] = useState(getTimeInSaoPaulo());
      const [trabalhando, setTralhando] = useState(false);
      const [runingTrabalhandoDepAlm, setRuningTrabalhandoDepAlm] = useState(false)
      const [inRange, setInRange] = useState(false);
    
      const [diaDaSemana, setDiaDaSemana] = useState('');
    
      const segundosAtAlmoco = calcularDiferencaDeTempo(horaEntrada,minutoEntrada,horaAlmoco, minutoAlmoco);//Segundos até almoco
      const segundosAtSaida = calcularDiferencaDeTempo(horaAlmoco, minutoAlmoco, horaSaida, minutoSaida)
    
      //Variaveis de geolocalização
      const [locationStatus, setLocationStatus] = useState('Checking location...');
      const [distance, setDistance] = useState(null);
      const [hasPermission, setHasPermission] = useState(false);
    
    
    
    
      
      const [trabalhandoAntAlm, setTrabalhandoAntAlm] = useState(true);
      const [trabalhandoDepAlm, setTrabalhandoDepAlm] = useState(false);
    
      const [trabalhoDiaConcluidoCompletro,setTrabalhoDiaConcluidoCompleto] = useState(false);
    
      const [esperandoFinalizar, setEsperandoFinalizar] = useState(false);
      const [dentroHorario, setDentroHorario] = useState(false);
      const [noHorarioAlmoco, setNoHorarioAlmoco] = useState(false);
    
    
      //Variaveis de Horario
      const [posFirstHor, setPosFirstHor] = useState(false);
      const [posSegHor, setPosSegHor] = useState(false);
      const [posTerHor, setPosTerHor] = useState(false);
    
      //Numero Gerente
      const [numGerente, setNumGerente] = useState("");
      const [nomePosto, setNomePosto] = useState("")
      useEffect(() => {
        verificaLogin(token)
        const returnNumero = async () => {
          try{
            const modelo = await axios.get(`${API_URL_BUSCA_NUM_GERENTE}/${idPosto.toString()}`);
            if(modelo.data != 200){
              setNumGerente(prevItem => modelo.data)
            } else {
              setNumGerente(prevItem => "")
            }
    
          }catch(error){
            console.log("Erro ao buscar número! ")
          }
        }
        returnNumero();
      }, [idFuncPag])



      const openWhatsApp = () => {
        
        const message = "Venho por meio deste informar que, devido a questões de saúde, fui atendido por um médico que recomendou meu afastamento das atividades laborais. Em anexo, envio o atestado médico que comprova a necessidade deste afastamento.\n\n*Lembrete: Não esqueça de encaminhar o atestado para a equipe técnica do PontoPosto (+5514996445490)*";
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${numGerente}&text=${message}`;
        window.open(whatsappUrl, '_blank');
      };

      useEffect(() => {
    
        const timer = setTimeout(() => {
            setIsLoading( prevItem => false);
            segundosFunction(true)
        }, 3000); // 10 segundos
    
        return () => clearTimeout(timer);
      }, []);

      useEffect(() => {
        const fetchBuscaPosto = async () => {
          try{
            const modelo = await axios.get(`${API_URL_BUSCA_POSTO}/${idPosto.toString()}`);
            if(modelo.data != 200){
              setTempoDeAlmoco(prevItem => modelo.data.TempoDeAlmoco);
              const variDentroUseEffect = convertMinutesToTime(modelo.data.TempoDeAlmoco);
              setTempoDeAlmocoFormatoAPI(prevItem => variDentroUseEffect);

              setNomePosto(prevItem => modelo.data.Nome)
            }
    
          } catch(error){
            console.error("Erro ao buscar Posto : ", error);
            console.log("Erro ao buscar Posto: ", error)
          }
        }
        fetchBuscaPosto();

    
      }, [entraPagina,idFuncPag])

      useEffect(() => {
    

        const fetchBuscaDia = async () => {
      
          try {
            const modeloImg = await axios.get(`${API_URL_BUSCA_FUNC}/${idFuncPag.toString()}`)
            if(modeloImg.data != 200){
              setImagemFunc(prevItem => modeloImg.data.urlImage);
              setNomeFunc(prevItem => modeloImg.data.Nome)
            }else {
              setImagemFunc(prevItem => "");
              setNomeFunc(prevItem => "")
            }
            const modelo = await axios.post(`${API_URL_BUSCA_DIA_TRAB}/${idFuncPag.toString()}`, {
              DataSelecionada: dataNow
            });
            if(modelo.data != 200){
              const dados = modelo.data
              console.log(dados)
              setId(prevId => dados.id);
              setIdFunc(prevFunc => dados.idFunc);
              setTrabalhoAntAlmConcluido(prevItem => dados.trabalhoAntAlmConcluido);
              setTrabalhoDepAlmConcluido(prevItem =>  dados.trabalhoDepAlmConcluido);
              setAlmConcluido(prevItem => dados.almConcluido);
              setTrabalhoDiaConcluido(prevItem =>  dados.TrabalhoDiaConcluido);
              setHoraDeEntrada(prevItem =>  dados.horaDeEntrada);
              sethoraEfetivaDeEntrada(prevItem =>  dados.horaEfetivaDeEntrada);
              setHoraDeSaida(prevItem => dados.horaDeSaida);
              setHoraEfetivaDeSaida(prevItem =>  dados.horaEfetivaDeSaida);
              setHoraAlmoco(prevItem =>  dados.horaAlmoco);
              setHoraEfetivaDeAlmoco(prevItem =>  dados.horaEfetivaDeAlmoco);
              setData(prevItem => dados.Data);
              setNumDia(prevItem => dados.numDia);
              setIdSemana(prevItem => dados.idSemana);
              setTrabalhandoAPI(prevItem => dados.trabalhando);
              setAlmocando(previtem => dados.almocando);
              setAlegou(previtem => dados.Alegou)
              setDataFormato(previtem => formatDate(dados.Data))
              setHorarioDoLetreiro(dados.horaDeEntrada)
      
      
      
              //Var ProgessBar
              var horaEntForm = convertTime(dados.horaDeEntrada)
              var horaAlmocoForm = convertTime(dados.horaAlmoco)
              var horaAlmocoSaidaForm = convertTime(dados.horaAlmoco) //Alterar HorarioAlmoco seguindo o padrão de tempo
              var horaSaidaTrabForm = convertTime(dados.horaDeSaida)
              setHoraEntradaFormato(prevItem => horaEntForm )
              setHoraAlmocoFormato(prevItem => horaAlmocoForm)
              setHoraSaidaAlmocoFormato(prevItem => horaAlmocoSaidaForm)
              setHoraSaidaFormato(prevItem => horaSaidaTrabForm)

            }
          } catch (error) {
            console.log("ErroAPI", error);
          }
        };
        fetchBuscaDia();
    
        }, [entraPagina,idFuncPag])

        useEffect(() => {
          const intervalo = setInterval(() => {
            const horaAtualGet = moment().format('HH:mm');
            if (moment(horaAtualGet, 'HH:mm').isSameOrAfter(moment(horarioDoLetreiro, 'HH:mm'))) {
              setCorLetreiro(prevItem => true); // Muda a cor para verde se a hora atual for igual ou maior
            } else {
              setCorLetreiro(prevItem => false); // Mantém preto se a hora do letreiro for maior
            }
          }, 1000);
      
          // Limpa o intervalo ao desmontar o componente
          return () => clearInterval(intervalo);
        }, [horarioDoLetreiro, horaGet, varLetreiro]);

        const funcaoLetreirobotao = async () => {
          try{
            const modelo = await axios.post(`${API_URL_BUSCA_DIA_TRAB}/${idFuncPag.toString()}`, {
              DataSelecionada: dataNow
            });
            if(modelo.data != 200){
              const dados = modelo.data;
              const modelo1 = await axios.post(`${API_ULR_BUSCA_HORAEFTENTRADA_BOL}/${idFuncPag.toString()}`, {
                Data: dataNow
              });
              if(modelo1.data != 200){
                if(dados.trabalhoAntAlmConcluido == false && dados.trabalhoDepAlmConcluido == false && dados.almConcluido == false && dados.trabalhando == false){
                  setHorarioDoLetreiro(prevItem => horaDeEntrada);
                }
                if(modelo1.data == true && dados.almConcluido == false) {
                  setHorarioDoLetreiro(prevItem => horaDeAlmoco);
                }
                if(dados.trabalhoAntAlmConcluido == true && dados.almocando == true){
                  setHorarioDoLetreiro(prevItem => horaSaidaAlmoco);
                }
                if(dados.almConcluido == true){
                  setHorarioDoLetreiro(prevItem => horaDeSaida);
                }
                if(dados.trabalhoAntAlmConcluido == true && dados.almConcluido == true && dados.TrabalhoDiaConcluido == true){
                  setTrabalhoFinalizado(prevItem => true)
                  setHorarioDoLetreiro(prevItem => horaDeSaida);
                }
              }
    
            }
          }catch(error){
            console.log("Erro na funcao letreiro ", error)
          }
        }

        useEffect(() => {//ALTERADO NESTA VERSAO, DEVE-SE ANALISAR SE ESTA FUNCIONANDO CORRETAMENTE, CASO CONTRARIO RETIRAR CHAMADA DA FUNÇÃO
          const func = async () => {
            try{
              const modelo = await axios.post(`${API_URL_BUSCA_DIA_TRAB}/${idFuncPag.toString()}`, {
              DataSelecionada: dataNow
            });
            if(modelo.data != 200){
              const dados = modelo.data
              setTrabalhandoAPI(prevItem => dados.trabalhando)
            }
    
            } catch (error) {
              console.log("ErroAPI", error);
            }
          }
    
          const funcaoLetreiro = async () => {
            try{
              const modelo = await axios.post(`${API_URL_BUSCA_DIA_TRAB}/${idFuncPag.toString()}`, {
                DataSelecionada: dataNow
              });
              if(modelo.data != 200){
                const dados = modelo.data;
              const modelo1 = await axios.post(`${API_ULR_BUSCA_HORAEFTENTRADA_BOL}/${idFuncPag.toString()}`, {
                Data: dataNow
              });
              if(modelo1.data != 200){
                if(dados.trabalhoAntAlmConcluido == false && dados.trabalhoDepAlmConcluido == false && dados.almConcluido == false && dados.trabalhando == false){
                  setHorarioDoLetreiro(prevItem => horaDeEntrada);
                }
                if(modelo1.data == true && dados.almConcluido == false) {
                  setHorarioDoLetreiro(prevItem => horaDeAlmoco);
                }
                if(dados.trabalhoAntAlmConcluido == true && dados.almocando == true){
                  setHorarioDoLetreiro(prevItem => horaSaidaAlmoco);
                }
                if(dados.almConcluido == true && dados.trabalhando == true){
                  setHorarioDoLetreiro(prevItem => horaDeSaida);
                }
                if(dados.trabalhoAntAlmConcluido == true && dados.almConcluido == true && dados.TrabalhoDiaConcluido == true){
                  setTrabalhoFinalizado(prevItem => true)
                  setHorarioDoLetreiro(prevItem => horaDeSaida);
                }
              }
              }
              
    
            }catch(error){
              console.log("Erro na funcao letreiro ", error)
            }
          }
    
          func();
          funcaoLetreiro();
        }, [horaGet,entraPagina,varLetreiro,idFuncPag])

        function verificarHorario10(horaEntrada, minutoEntrada, horaAlmocoEntrada, minutoAlmocoEntrada, tempoDeAlmoco, horaSaida, minutoSaida) {
      
          const agora = new Date();
          const horaAtual = agora.getHours();
          const minutosAtual = agora.getMinutes();
          const horarioAtual = moment().set({ hour: horaAtual, minute: minutosAtual });
                    
          

          const FormatoEntrada = moment().set({ hour: horaEntrada, minute: minutoEntrada });
          const FormatoSaida = moment().set({ hour: horaSaida, minute: minutoSaida });
          
          let FormatoAlmoco, FormatoAlmocoSaida;
        
          if (tempoDeAlmoco) {
            FormatoAlmoco = moment().set({ hour: horaAlmocoEntrada, minute: minutoAlmocoEntrada });
            const [almocoHoras, almocoMinutos] = tempoDeAlmoco.split(':').map(Number);
            const horaAlmc = parseInt(horaAlmocoEntrada, 10) + parseInt(almocoHoras, 10) ;
            const mimAlmc = parseInt(minutoAlmocoEntrada, 10) + parseInt(almocoMinutos, 10);
            FormatoAlmocoSaida = moment().set({ hour: horaAlmc, minute: mimAlmc });
            setHoraSaidaAlmoco(prevItem => FormatoAlmocoSaida);//FormatoAlmocoSaida
            const horarioSaidaInternoFunc = formatarHorario(FormatoAlmocoSaida);
            setHoraSaidaAlmoco( prevItem => horarioSaidaInternoFunc);
            const horaSaidaAlmocoFormula = convertTime(horaSaidaAlmoco);

            sethoraSaidaFormatoProgress(horaSaidaAlmocoFormula);
    
          }
        
          let resultadoDoVerificaHorario = 0;
          if (horarioAtual.isBetween(FormatoEntrada, FormatoAlmoco, null, '[)')) {
            resultadoDoVerificaHorario = 1; // Entrada
          } 
          if (horarioAtual.isBetween(FormatoAlmoco, FormatoAlmocoSaida, null, '[)')) {
            resultadoDoVerificaHorario = 2; // Almoco
          } 
          if (horarioAtual.isBetween(FormatoAlmocoSaida, FormatoSaida, null, '[)')) {
            resultadoDoVerificaHorario = 3; // Saida
          }
          if (horarioAtual.isSameOrAfter(FormatoSaida)) {
            resultadoDoVerificaHorario = 4; // Após a saída
          }
          if (horarioAtual.isBefore(FormatoEntrada)) {
            resultadoDoVerificaHorario = 5; // Antes da entrada
          }
              return resultadoDoVerificaHorario;
        }

        useEffect(()=>{//Alterar esse UseEffect para utilizar a função de cima.
    
          const result = verificarHorario10(horaEntrada, minutoEntrada, horaAlmoco, minutoAlmoco,tempoDeAlmocoFormatoAPI, horaSaida, minutoSaida);//Alterar o Almoco Futuramente Modelo
          console.log(result)
          if(result == 1 ){
            console.log("10")
            setPosFirstHor(true); //Entrada (ForamtoEntrada, FormatoAlmoco)
            setResultadoVerificarHorario(true);
          } if (result == 2){
            setPosSegHor(true); // Almoco (formatoAlmoco, FormatoALmocoSaida)
            console.log("20")
          } if( result == 3){
            setPosTerHor(true); // Saida (Almoco saida, FormatoSaida)
            setResultadoVerificarHorario(false)
            console.log("30")
          } if(result == 4){
            console.log("40")
          }
      
      
      
        
        }, [horaGet,idFuncPag, entraPagina, varLetreiro])

        useEffect(()=>{
          const nome = retornaDia(dia);
          setDiaDaSemana(nome);
        },[])

        const [timeoutEsgotado, setTimeoutEsgotado] = useState(false);

        const [latitude, setLatitude] = useState();
        const [longitude, setLongitude] = useState();
        const [error, setError] = useState(false);




        useEffect(() => {
          if(alegou == true){
            setInRange(true)
          }
          if(error){
            if(alegou == true){
              setInRange(true)
            } else {
              handleOpen()
            }
          }
        },[timeoutEsgotado,alegou])

        const checkLocation = async () =>{
          try{
            const modelo = await axios.get(`${API_URL_BUSCA_POSTO}/${idPosto.toString()}`);
            const dados = [modelo.data.latitude, modelo.data.longitude]

            const calculaDistancia = calculateDistance(latitude,longitude, dados[0], dados[1])
            setDistance(calculaDistancia);

            if(calculaDistancia < 0.300){
              setInRange(prevItem => true)
            } else {
              setInRange(prevItem => false)

            }
          }catch (error) {
            console.error('Error getting location:', error.message);
          }
          
        }

        function getLocation(callback) {
          if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(
              function(poosition) {
                callback(poosition.coords.latitude.toFixed(14), poosition.coords.longitude.toFixed(14));
              },
              function(error) {
                setError(true)
              }
            );
          } else {
            setError(true)
          }
        }
        function setLocation(latitude, longitude) {
          setLatitude(latitude)
          setLongitude(longitude)
        }

        useEffect(() => {
          getLocation(setLocation)
          checkLocation()
        }, [idFuncPag, latitude, longitude]);

        
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getTimeInSaoPaulo());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  
  useEffect(()=>{

    const result = verificarHorario10(horaEntrada, minutoEntrada, horaAlmoco, minutoAlmoco,tempoDeAlmocoFormatoAPI, horaSaida, minutoSaida);//Alterar o Almoco Futuramente Modelo
    if(result === 1 || result === 2 || result == 3){
      setDentroHorario(prevItem => true);
    }else{
      setDentroHorario(prevItem => false);
    }
    const verificaStatusTrabalhando = async () => {
      try{
        const modelo = await axios.post(`${API_URL_BUSCA_TRABALHANDO}/${idFunc.toString()}`, {
          DataSelecionada: dataNow
        });
        setTrabalhandoAPI(prevItem => modelo.data)

      }catch(error){
        console.log(error)

      }
    }
    verificaStatusTrabalhando();
  },[horaGet, entraPagina,idFuncPag, varLetreiro]);


  const AtualizaTrabalhando = async () => {
    const modelo = await axios.post(`${API_URL_BUSCA_TRABALHANDO}/${idFunc.toString()}`, {
      DataSelecionada: dataNow
    });
    if(modelo.data != 200){
      setTrabalhandoAPI(prevItem => modelo.data);
    } else{
      setTrabalhandoAPI(prevItem => false);
    }

  }

  const funcConfig =() => {
    //navigation.navigate('PaginaConfig', {idFunc: idFuncPag, idPosto: idPosto});
  }
  
  const botaoFinalizar = async () => {
    funcaoLetreirobotao();
     if(almConcluido == true){
      botao4()
    } else {
      botao3()
    }
  }


  const botaoIniciar = async () => {
    funcaoLetreirobotao();
    if(trabalhoAntAlmConcluido == false){
      botao1();
    }else {
      botao5();
    }
  }

  const botao1 = async () => {

    if(dentroHorario == true){
      if(inRange == true){
          setProgress(prevItem => 0);
          setCounting(prevItem => true);
          setCurrentTime(prevItem => getTimeInSaoPaulo());
          setElapsedSeconds(prevItem => 0);
          setTrabalhandoAntAlm(prevItem => true);
          setTralhando(prevItem => true);
          altEntraPagina();
          const response = await axios.put(`${API_URL_HORA_DEFINT_ENTRADA}/${idFunc.toString()}`,{ //MUDAR SEMANA AO MUDAR COD
            Data: Data,
            Hora : horaGet
          });
          const modelo = await axios.put(`${API_URL_RECEBE_TRABALHANDO}/${idFunc.toString()}`, {
            Data: dataNow,
            Trabalhando: true
          });
          AtualizaTrabalhando();
      }
    } else {
      showAlertCancel("Horários", `Seu turno começa as ${horaEntrada}:${minutoEntrada}`)
    }
  };

  const botao2 = () => {
    //Variavel DentroHorario = true mas a variavel inRange não, Este tem o objetivo de informar que está errado
    showAlertCancel("Distancia Permitida", "Fora da localidade do Posto!")
  };

  const botao3 = async () => {
   //trabalhandoAntAlm muda para False após a foto, trabalhoAntAlmConcluido vira true e atualiza o bd, Variavel de horario de almoco vira true
    //Tem o objetivo de finalizar o trabalho antes do almoco
    const result = verificarHorario10(horaEntrada, minutoEntrada, horaAlmoco, minutoAlmoco,tempoDeAlmocoFormatoAPI, horaSaida, minutoSaida);//Alterar o Almoco Futuramente Modelo

    if(result === 2){
      setNoHorarioAlmoco(prevItem => true);
      setTrabalhandoAntAlm(prevItem => false);
      setTrabalhoAntAlmConcluido(prevItem => true);
      const response = await axios.put(`${API_URL_CONCLUI_TRAB_ANT_ALM}/${idFunc.toString()}`,{ //MUDAR SEMANA AO MUDAR COD
        Data: Data,
      });
      const response_def_Ent = await axios.put(`${API_URL_HORA_DEFINT_ALMOCO}/${idFunc.toString()}`,{ //MUDAR SEMANA AO MUDAR COD
        Data: Data,
        Hora : horaGet
      });
      const modelo = await axios.put(`${API_URL_RECEBE_TRABALHANDO}/${idFunc.toString()}`, {
        Data: dataNow,
        Trabalhando: false
      });
      const modelo1 = await axios.put(`${API_URL_BUSCA_ALMOCANDO}/${idFunc.toString()}`, {
        Data: dataNow,
        Almocando: true
      });
      altEntraPagina();
      } else {
      showAlertCancel("Horario", `Seu horário de almoço começa as ${horaAlmoco}:${minutoAlmoco}`)
    }


  };

  const botao4 = async () => {
    //Se trabalhandoDepAlm for igual a true, se mostra esse botão, após a foto, o código muda trabalhoDepAlmConcluido como true

    
    const result = verificarHorario10(horaEntrada, minutoEntrada, horaAlmoco, minutoAlmoco,tempoDeAlmocoFormatoAPI, horaSaida, minutoSaida);//Alterar o Almoco Futuramente Modelo

    if(result == 4){
      setTrabalhoDepAlmConcluido(prevItem => true);
      setTralhando(prevItem => false)
      setTrabalhoDiaConcluidoCompleto(prevItem =>true);
      const response = await axios.put(`${API_URL_CONCLUI_TRAB_DIA}/${idFunc.toString()}`,{ //MUDAR SEMANA AO MUDAR COD
        Data: Data,
      });
      const response_def_Said = await axios.put(`${API_URL_HORA_DEFINT_SAIDA}/${idFunc.toString()}`,{ //MUDAR SEMANA AO MUDAR COD
        Data: Data,
        Hora : horaGet
      });
      const response_def_Trab_Pos_Alm = await axios.put(`${API_URL_CONCLU_TRAB_POS_ALM}/${idFunc.toString()}`, {
        Data: Data
      })
      const modelo = await axios.put(`${API_URL_RECEBE_TRABALHANDO}/${idFunc.toString()}`, {
        Data: dataNow,
        Trabalhando: false
      });
      altEntraPagina();
     } else {
      const horario = horaDeSaida.split(":")
      showAlertCancel("Horario", `Seu turno acaba as ${horario[0]}:${horario[1]}`)
    }

  }

  const botao5 = async () => {
    //Se a Variavel trabalhandoAntAlmConcluido for igual a true, aparece esse botão, inRange e no horario devem ser true, mas a variavel horario de almoco vira false trabalhandoDepAlm = true 
    //if(trabalhoAntAlmConcluido == false){
    //  const modelo = await axios.post("http://172.20.10.3:3004/buscaDiaTrabalho/1", {
    //    DataSelecionada: dataNow
    //  });
    //  const dados = modelo.data
    //  setTrabalhoAntAlmConcluido(prevItem => dados.trabalhoAntAlmConcluido);
    //  console.log(dados)
    //}

    if(trabalhoAntAlmConcluido==true){//Verificar depois a questão de logicas de variaveis
      console.log("e")
        if(inRange == true){
          const result = verificarHorario10(horaEntrada, minutoEntrada, horaAlmoco, minutoAlmoco,tempoDeAlmocoFormatoAPI, horaSaida, minutoSaida);//Alterar o Almoco Futuramente Modelo
          if(result === 3){
            setNoHorarioAlmoco(prevItem => false);
            setProgress(prevItem => 0);
            setCounting(prevItem => true);
            setCurrentTime(prevItem => getTimeInSaoPaulo());
            setElapsedSeconds(prevItem => 0);
            setTrabalhandoDepAlm(prevItem => true);
            setAlmConcluido(prevItem => true);
            const response = await axios.put(`${API_URL_CONCLUI_ALM}/${idFunc.toString()}`,{ //MUDAR SEMANA AO MUDAR COD
              Data: Data,
            });
            const modelo = await axios.put(`${API_URL_RECEBE_TRABALHANDO}/${idFunc.toString()}`, {
              Data: dataNow,
              Trabalhando: true
            });
            const modelo1 = await axios.put(`${API_URL_BUSCA_ALMOCANDO}/${idFunc.toString()}`, {
              Data: dataNow,
              Almocando: false
            });
            AtualizaTrabalhando();
            
         } else {
            const horario = horaSaidaAlmoco.split(":");
            showAlertCancel("Horario", `Seu próximo turno começa as ${horaAlmoco}:${minutoAlmoco}`)
          }
      } else {
        showAlertCancel("Distancia Permitida", "Fora da localidade do Posto!")
      }
      
    }

  
  }


  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const [sim, setSim] = useState(false);

  const handleSim = () => {
    setSim(true);
  };

  const handleNao = () => {
    setSim(false);
  };

  const enviarAlegaçãoPosto = async () => {
    if(sim == true){
      const API_URL_ALEGACAO = "https://api-production-d9e6.up.railway.app/alegacaoPosto"; //IDFUNC
      try{
        const response = await axios.post(`${API_URL_ALEGACAO}/${idFunc}`, {
          Data: Data,
          idDiaTrab: id
        });
        if(response.data != 200){
          alert("Alegação enviada!")
          setInRange(true)
          setIsOpen(false)
        } else{
          setInRange(false)
          setIsOpen(false)
        }
      }catch(error){
        console.log("Erro ao enviar alegação")
      }
    } else{
      setInRange(false)
      setIsOpen(false)
    }
    
  }


  const handleForaRange = () => {

  };

  const handleForConclu = () => {
    setEsperandoFinalizar(true)
    setRuningTrabalhandoDepAlm(true)
  }

  const minhaFuncao = () => {
    window.location.href = `/calendar?token=${token}`;
    //navigation.navigate('MyCalendarPage', {idFunc: idFuncPag});
  };

  const ajustes = () => {
    window.location.href = `/PaginaConfig?token=${token}`;
    //navigation.navigate('MyCalendarPage', {idFunc: idFuncPag});
  };


  return (
    <div style={styles.PrinView}>
      <div style={styles.header}>
      <img  src={imagemFunc.toString()}  style={styles.logo}/>
        <div style={styles.letreiro}>
          <div style={styles.divNome}>
            <p style={styles.title}>{nomeFunc.toString()}</p>
          </div>
            <div>
              <p style={styles.NomePosto}>{nomePosto}</p>
            </div>
        </div>
        

        <div style={styles.parteDireita}>

        </div>
      </div>

      <div style={styles.PaginaToda}>
        <div style={styles.container}>
          <div style={styles.relogio}>
            <div style={styles.containerLetreiro}>
              <h3 style={styles.letreiroDiaSemana} >{diaDaSemana}</h3>
              <div style={styles.containerDataHor}>
                <div style={styles.data}>
                  <h3 style={styles.textData}>Data</h3>
                  <h3 style={styles.textVarData}>{dataFormato}</h3>
                </div>
                <div>

                </div>
                {isLoading ? (
                  <div style={styles.Hor}>
                    <img  style={styles.Gif} src={gif}/>
                  </div>
                ):(
                  corLetreiro ? (
                    <div style={styles.Hor}>
                      <h3 style={styles.textData}>Próximo Horário</h3>
                      <h3 style={styles.textVarDataVerd}>{horarioDoLetreiro}</h3>
                    </div>
                  ): (
                    <div style={styles.Hor}>
                      <h3 style={styles.textData}>Próximo Horário</h3>
                      <h3 style={styles.textVarDataVerm}>{horarioDoLetreiro}</h3>
                    </div>
                  )
                )}

              </div>


            </div>
            <div style={styles.spiral}>
              {resultadoVerificarHorario ? (
                  <SpiralProgressBar  width={200} height={200} startTime={new Date(horaEntradaFormato)} endTime={new Date(horaAlmocoFormato)} isRunning={trabalhandoAPI} />
                ): (
                  <SpiralProgressBar  width={200} height={200} startTime={new Date(horaSaidaFormatoProgress)} endTime={new Date(horaSaidaFormato)} isRunning={trabalhandoAPI} />
                )}

                <div style={styles.containerBotao}>
                  {trabalhoFinalizado ? (
                    <h5 style={styles.TextoFinalizado}>Trabalho Finalizado!</h5>
                  ): (
                    trabalhandoAPI ?(
                      <button onClick={botaoFinalizar} style={styles.botaoFinalizar}><p style={styles.textButton}>Finalizar</p></button>
                    ):(
                      inRange ? (
                        <button onClick={botaoIniciar} style={styles.botaoIniciar}><p style={styles.textButton}>Iniciar</p></button>
                      ): (
                        <button style={styles.botaoFora}><p style={styles.textButton}>Fora</p></button>
                      )
                    )
                  )}
                </div>
            </div>


          </div>
        </div>
        {isOpen ? (
      
          <div  style={styles.modalStyle}>
            <div style={styles.containerModal}>
              <h3 style={styles.h3Modal}>Falha ao registrar localização!</h3>
              <p style={styles.pModal}>Você alega que está no perímetro do {nomePosto}?</p>

                <div style={styles.simModel}>
                  <input
                    type="checkbox"
                    id="sim"
                    checked={sim}
                    onChange={handleSim}
                  />
                  <label for="sim">Sim</label>
                </div>

                <div style={styles.naoModel}>
                  <input
                    type="checkbox"
                    id="nao"
                    checked={!sim}
                    onChange={handleNao}
                  />
                  <label for="nao">Não</label>
                </div>
                <button style={styles.botaoEnviar} onClick={enviarAlegaçãoPosto}><p style={{color: 'green', marginTop: 3}}>Enviar</p></button>
          </div>
        </div>
      ): (
        <div>
        </div>
      )}

      </div>
        <div style={styles.tabBar}>
              <button style={styles.tabBarButton}>
                <img src={caledario} style={styles.icon} onClick={minhaFuncao}/> 
              </button>
              <button style={styles.tabBarButton}>
                <img src={information} style={styles.icon} onClick={openWhatsApp}/> 
              </button>
              <button style={styles.tabBarButton}>
                <img src={config} style={styles.icon} onClick={ajustes}/>
              </button>
          </div>
    </div>
  )
}

const styles = {
  PrinView: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: '#f0f0f0',
    borderBottom: '1px solid #ccc',
    borderRadius: 10,
    height: 90,
  },
  logo: {
    width: '90px',
    height: '90px',
    marginRight: 0,
    borderRadius: 100,
    marginTop: 10,
  },
  letreiro: {
    marginLeft: 10,
    marginTop: -10,

  },
  divNome:{
    
  },

  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 20,
    width: 300,
    fontWeight: 'bold',

    
  },
  NomePosto:{
    marginTop: -15
  },
  PaginaToda: {
    height: 445,
    width: 300,
    textAlign: 'center',
    backgroundColor: 'white',
    marginLeft: "15%",
    marginTop: '10%',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
    borderRadius: 16,
    marginTop: 45
  },
  containerLetreiro: {

    height: 125,
  },
  letreiroDiaSemana: {
    fontSize: 25,
    padding: 0
  },
  containerDataHor: {
    display: 'flex',
    marginTop: -29,
    marginLeft: "10%",

  },
  data: {
    marginRight: 40,
  },
  textData: {
    fontSize: 19
  },
  textVarData: {
    fontSize: 26,
    marginTop: -12
  },
  Hor: {

  },
  Gif:{
    height: 50,
    width: 50,
    alignItems: 'center',
    marginTop : '50%',
    marginLeft: '70%'
  },
  textVarDataVerd: {
    fontSize: 26,
    marginTop: -12,
    color: 'green'
  },
  textVarDataVerm: {
    fontSize: 26,
    marginTop: -12,
    color: 'red'
  },
  spiral: {

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250
    
  },
  containerBotao: {
    position: 'absolute',
    marginTop: "70%"
  },
  botaoFinalizar: {
    backgroundColor: 'red',
    textAlign: 'center',
    height: 40,
    width: 100,
    borderRadius: 8,

    
  },
  botaoIniciar: {
    backgroundColor: 'green',
    textAlign: 'center',
    height: 40,
    width: 100,
    borderRadius: 8,
    
  },
  botaoFora: {
    backgroundColor: 'rgba(255,242,0,1)',
    textAlign: 'center',
    height: 40,
    width: 100,
    borderRadius: 8,
    
  },
  textButton:{
    textDecoration: 'underline 1px solid #ccc',
    fontWeight: 'bold',
    color: 'white',
    fontSize: 22,
    marginTop: 6,
    marginLeft: -5
  },
  modalStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerModal:{
    height: 250,
    width: 300,
    backgroundColor: 'white',
    borderRadius: 5
  },
  h3Modal: {
    fontSize: 20
  },
  pModal:{

  },
  simModel:{
    fontWeight: 'bold',
    margin: 7
  },
  naoModel:{
    fontWeight: 'bold',
  },
  botaoEnviar:{
    textDecoration: 'underline 1px solid #ccc',
    fontWeight: 'bold',
    color: 'white',
    fontSize: 15,
    marginTop: 30,
    height: 20,

  },
  tabBar: {
    display: 'flex',

    height: 70,
    width: 260,
    marginLeft: 75,
    marginTop: 17,
  },
  tabBarButton: {
    margin: 10,
    height: 65,
    width: 75,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
  },
  icon: {
    height: 50,
    width: 50,
    
  }

}


export default CustomPage;